<svg xmlns="http://www.w3.org/2000/svg" width="41" height="36" viewBox="0 0 41 36">
    <g id="Group_91" data-name="Group 91" transform="translate(-453 -752)">
      <g id="Group_44" data-name="Group 44" transform="translate(141 230)">
        <g id="Rectangle_32" data-name="Rectangle 32" transform="translate(312 522)" fill="#fff" stroke="#707070" stroke-width="0.4">
          <rect width="41" height="36" rx="7" stroke="none"/>
          <rect x="0.2" y="0.2" width="40.6" height="35.6" rx="6.8" fill="none"/>
        </g>
        <g id="delete" transform="translate(279.978 527.544)" opacity="0.47">
          <g id="Group_40" data-name="Group 40" transform="translate(42.667)">
            <g id="Group_39" data-name="Group 39" transform="translate(0)">
              <path id="Path_7" data-name="Path 7" d="M64,101.941a2.767,2.767,0,0,0,2.768,2.768H77.84a2.767,2.767,0,0,0,2.768-2.768V85.333H64Z" transform="translate(-62.616 -79.797)"/>
              <path id="Path_8" data-name="Path 8" d="M57.2,1.384,55.815,0H48.9L47.511,1.384H42.667V4.152H62.043V1.384Z" transform="translate(-42.667)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
</svg>
