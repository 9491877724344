import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogModule, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { TwoDatesService } from './two-dates.service';

@Component({
  selector: 'app-pick-between-two-dates',
  templateUrl: './pick-between-two-dates.component.html',
  styleUrls: ['./pick-between-two-dates.component.scss'],
})
export class PickBetweenTwoDatesComponent implements OnInit {


  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Input() title: string;

  form: FormGroup = new FormGroup({
    fromDate: new FormControl(''),
    toDate: new FormControl('')
  });

  dialogConf = new MatDialogConfig();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialogRef<PickBetweenTwoDatesComponent>
  ) {


  }

  ngOnInit() {
    this.form = this.fb.group({
      fromDate: [this.fromDate ? this.fromDate : ''],
      toDate: [this.toDate ? this.toDate : '']
    })
  }
  // 

  onFormSubmit() {
    this.data = this.form.value;
    this.dialog.close(this.data)
  }

}
