import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-feedback-box',
  templateUrl: './feedback-box.component.html',
  styleUrls: ['./feedback-box.component.scss']
})
export class FeedbackBoxComponent implements OnInit {

  feedBackForm: FormGroup = new FormGroup({
    text: new FormControl('', Validators.required)
  });

  constructor(private http: HttpClient, private dialog: MatDialogRef<FeedbackBoxComponent>) { }

  ngOnInit(): void {
  }

  saveData(): void {
    if (this.feedBackForm.invalid) { return; }
    this.http.post('feedback', {}, {params: this.feedBackForm.value}).subscribe(res => {
      this.dialog.close();
    });
  }

}
