<div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses">
  <ng-container *ngIf="asideSelfMinimizeToggle">
    <button (click)="checkToShowLogo()" class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle"
      style="transform: rotate(180deg)">
      <span [inlineSVG]="'./assets/svg/go-back.svg'" cacheSVG="true" class="svg-icon svg-icon-xl"></span>
    </button>
  </ng-container>
</div>

<div style="overflow: auto; height: calc(100% - 320px);" class="aside-menu-wrapper menu-box" id="kt_aside_menu_wrapper">
  <ng-container *ngIf="disableAsideSelfDisplay">
    <a routerLink="/">
      <img alt="Logo" [attr.src]="headerLogo" />
    </a>
  </ng-container>
  <div id="kt_aside_menu" class="aside-menu" data-menu-vertical="1" data-menu-dropdown-timeout="500">
    <!--      [ngClass]="asideMenuCSSClasses"  -->
    <!--    [attr.data-menu-dropdown]="asideMenuDropdown"-->
    <!--    [attr.data-menu-scroll]="asideMenuScroll"-->
    <div style="height: 100%">
      <ul class="menu-nav" [ngClass]="ulCSSClasses">
        <ng-container *ngFor="let item of menuItems; let i = index">
          <li *ngIf="(!item.permission || roles.indexOf(item.permission) > -1) || item.visible" class="menu-item"
            aria-haspopup="true" routerLinkActive="menu-item-active">
            <a *ngIf="item.url; else expander" [routerLink]="item.url" [routerLinkActive]="'active'" class="menu-link" (click)="forChancelleryNotification(item.text)">
              <!-- <span [inlineSVG]="'./assets/svg/Layers.svg'" cacheSVG="true" class="svg-icon menu-icon"></span> -->
              <!-- <span [inlineSVG]="'./assets/svg/' + item.text + '.svg'" class="svg-icon menu-icon"></span> -->
              <div class="svg-icon menu-icon">
                <img [src]="'./assets/svg/' + item.text + '.svg'" alt="icon">
              </div>
              <!-- <span [inlineSVG]="'./assets/svg/' + item.text + '.svg'" cacheSVG="true" class="svg-icon menu-icon"></span> -->
              <span class="menu-text" [ngClass]="{'chancellery': item.text === 'Chancellery'}">{{ item.text | translate }}
                <ng-container *ngIf="item.text === 'Chancellery' && unreadChancelleryNotifications !== 0">
                  <span>{{ unreadChancelleryNotifications }}</span>
                </ng-container>
              </span>
            </a>
            <ng-template #expander>
              <a (click)="expandItem(item)" class="menu-link"
                *ngIf="(!item.permission || roles.indexOf(item.permission) > -1)">

                <span [inlineSVG]="'./assets/svg/' + item.text + '.svg'" cacheSVG="true"
                  class="svg-icon menu-icon"></span>
                <span class="menu-text">{{ item.text | translate}}</span>
              </a>
            </ng-template>
          </li>
          <ul *ngIf="item.children && item.expanded" class="menu-nav" style="margin-left: 30px; padding-top: 0"
            [ngClass]="ulCSSClasses">
            <ng-container *ngFor="let child of item.children">
              <li class="menu-item" aria-haspopup="true" *ngIf="roles.indexOf(child.permission) > -1">
                <a [routerLink]="child.url" routerLinkActive="active" class="menu-link">
                  <span [inlineSVG]="'./assets/svg/Layers.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
                  <span class="menu-text">{{ child.text | translate }}</span>
                </a>
              </li>
            </ng-container>
          </ul>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<ul class="menu-nav" [ngClass]="ulCSSClasses" style="margin-top: 20px;">
  <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active" [ngClass]="{'focused': !showLogo}"
    style="list-style: none">
    <a (click)="logout()" href="javascript:void(0)" class="logout-text">
      <span [inlineSVG]="'./assets/svg/logout.svg'" cacheSVG="true" class="svg-icon menu-icon" style="fill: rgba(255, 255, 255, 0.8);"></span>
      <span class="menu-text m-3" *ngIf="showLogo">{{ "signOut" | translate }}</span>
    </a>
  </li>
</ul>
<div *ngIf="showLogo" class="logo-text"></div>