<p class="d-none d-lg-block language-toggle m-4">
    <mat-button-toggle-group
        #group="matButtonToggleGroup"
        (change)="onLanguageChange(group.value)"
        [value]="currentLanguageValue"
    >
        <mat-button-toggle
        *ngFor="let language of languages"
        [value]="language.value"
        >
        {{ language.title }}
        </mat-button-toggle>
    </mat-button-toggle-group>
</p>

<p class="d-lg-none">
    <mat-select
        class="mr-10"
        [(value)]="currentLanguageValue"
        (selectionChange)="onLanguageChange($event.value)"
    >
        <mat-option *ngFor="let language of languages" [value]="language.value">
        {{ language.title }}
        </mat-option>
    </mat-select>
</p>