<div class="modal-header">
  <h1 mat-dialog-title class="modal-title">
    {{ 'date' | translate }}
  </h1>
  <app-dialog-close-icon mat-dialog-close></app-dialog-close-icon>
</div>

<div class="body">
  <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
    <div class="mb-10" style="height: 80px">
      <mat-form-field appearance="fill">
        <mat-label>{{ "range-picker" | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker" separator="to" required>
          <input matStartDate [placeholder]="'startDate' | translate" formControlName="fromDate" />
          <input matEndDate [placeholder]="'endDate' | translate" formControlName="toDate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error>{{ "required" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <button [disabled]="form.invalid && form.touched" mat-raised-button color="primary" class="mr-5" type="submit">
      {{ "save" | translate }}
    </button>
  </form>
</div>