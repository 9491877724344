<div
  [ngClass]="footerContainerCSSClasses"
  class="d-flex flex-column flex-md-row align-items-center justify-content-between"
>
  <div class="text-dark order-2 order-md-1">
    <span class="text-muted font-weight-bold mr-2"
      >{{ currentYear }} &copy;</span
    >
    <a
      href="javascript:void(0)"
      target="_blank"
      class="text-dark-75 text-hover-primary"
      ></a
    >
  </div>
  <div></div>
</div>
