  <form
    class="d-flex justify-content-start flex-wrap"
    style="gap: 12px"
    [formGroup]="extendSelect"
  >
    <ng-container *ngIf="displayedInputs.includes('semester')">
      <mat-form-field appearance="fill">
        <mat-label>{{'semester' | translate}}</mat-label>
        <mat-select formControlName="semester" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
          }}</mat-option>
          <mat-option
            *ngFor="let semester of semesterEnum"
            [value]="semester"
            >{{ semester }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <!-- <mat-form-field appearance="fill">
      <mat-label>group</mat-label>
      <mat-select formControlName="group" multiple>
        <mat-option *ngFor="let group of groupsList" [value]="group">{{group}}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <ng-container *ngIf="displayedInputs.includes('campus')">
      <mat-form-field appearance="fill">
        <mat-label>{{'campus' | translate}}</mat-label>
        <mat-select formControlName="campus" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
          }}</mat-option>
          <mat-option
            *ngFor="let campus of campusOptions"
            [value]="campus.value"
            >{{ campus.display | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('studingLanguage')">
      <mat-form-field appearance="fill">
        <mat-label>{{'studingLanguage' | translate}}</mat-label>
        <mat-select formControlName="studingLanguage" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
          }}</mat-option>
          <mat-option
            *ngFor="let language of studingLanguageOptions"
            [value]="language.value"
            >{{ language.display | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('modules') && modules">
      <mat-form-field appearance="fill">
        <mat-label>{{'module' | translate}}</mat-label>
        <mat-select formControlName="module" [multiple]="false">
          <mat-option
            *ngFor="let module of modules"
            [value]="module.entityId"
            >{{module['name' + lang]}}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="citizenship.length && displayedInputs.includes('citizenship')"
    >
      <mat-form-field appearance="fill">
        <mat-label>{{'citizenship' | translate}}</mat-label>
        <mat-select formControlName="citizenship" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
          }}</mat-option>
          <mat-option *ngFor="let citizen of citizenship" [value]="citizen">{{
            citizen
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('program')">
      <mat-form-field appearance="fill">
        <mat-label>{{'program' | translate}}</mat-label>
        <mat-select formControlName="program" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
          }}</mat-option>
          <mat-option
            *ngFor="let program of programOptions"
            [value]="program.value"
            >{{ program.display | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="displayedInputs.includes('moduleQty')">
      <mat-form-field appearance="fill" style="max-width: 250px;">
        <mat-label>{{'moduleQty' | translate}}</mat-label>
        <mat-select formControlName="moduleQty" [multiple]="false">
          <mat-option [value]="0">*</mat-option>
          <mat-option [value]="3">3</mat-option>
          <mat-option [value]="6">6</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('groupsFormationOrRating')">
      <mat-form-field appearance="fill" style="max-width: 250px;">
        <mat-label>{{'searchParam' | translate}}</mat-label>
        <mat-select formControlName="isRating" [multiple]="false">
          <mat-option [value]="false">{{'fillGroups' | translate}}</mat-option>
          <mat-option [value]="true">{{'rating' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('courseSemesters') && extendSelect.value.isRating">
      <mat-form-field appearance="fill" style="max-width: 250px;">
        <mat-label>{{'course' | translate}}</mat-label>
        <mat-select formControlName="courseSemesters" [multiple]="true">
          <mat-option *ngFor="let course of [1,2,3,4,5,6,7,8,9,10,11,12]" [value]="course">{{course}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('groupStatus')">
      <mat-form-field appearance="fill">
        <mat-label>{{'status' | translate}}</mat-label>
        <mat-select formControlName="groupStatus">
          <mat-option
            *ngFor="let program of [{display: 'Active', value: 'active'}, {display: 'archived', value: 'archived'}]"
            [value]="program.value"
          >{{ program.display | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('sesion')">
      <mat-form-field appearance="fill">
        <mat-label style="white-space: normal">{{'academicSeason' | translate}}</mat-label>
        <mat-select formControlName="sesion" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
          }}</mat-option>
          <mat-option
            *ngFor="let season of academicSeasonOptions"
            [value]="season.value"
            >{{ season.display | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('status')">
      <mat-form-field appearance="fill">
        <mat-label>{{'status' | translate}}</mat-label>
        <mat-select formControlName="status" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
          }}</mat-option>
          <mat-option
            *ngFor="let status of studentStatusOptions"
            [value]="status.value"
            >{{ status.display | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('gpaSemester')">
      <div style="width: 210px;">
      <mat-form-field appearance="fill" class="gpaSemester">
        <mat-label>{{'gpaSemester' | translate}}</mat-label>
        <mat-select formControlName="gpaSemester" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
          }}</mat-option>
          <mat-option
            *ngFor="let semester of semesterEnum"
            [value]="semester"
            >{{ semester }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('sortByGPA')">
      <div style="width: 210px;">
        <mat-form-field appearance="fill" class="sortByGPA">
          <mat-label>{{'sortByGPA' | translate}}</mat-label>
          <mat-select formControlName="sortByGPA">
            <mat-option [value]="true">{{ 'Dialog.yeas' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'Dialog.no' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>


    <ng-container *ngIf="displayedInputs.includes('lecturers')">
      <app-dropdown
        [list]="lecturers"
        [placeholder]="'lecturer' | translate"
        [activeItem]="extendSelect.get('lecturer').value || ''"
        [inputField]="true"
        (valueChange)="selectLecturer($event)"
      ></app-dropdown>
    </ng-container>


    <ng-container *ngIf="displayedInputs.includes('curations')">
      <mat-form-field appearance="fill">
        <input [placeholder]="'curation' | translate" formControlName="curation" type="text" matInput />
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayedInputs.includes('groups')">
      <mat-form-field appearance="fill">
        <mat-label>{{'group' | translate}}</mat-label>
        <mat-select formControlName="group" [multiple]="multiselect">
          <mat-option [value]="''" *ngIf="!multiselect">{{
            "*" | translate
            }}</mat-option>
          <mat-option
            *ngFor="let campus of groups"
            [value]="campus.value"
          >{{ campus.display | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <!-- <ng-container *ngIf="displayedInputs.includes('sort')">

        <mat-checkbox  class="mt-3" formControName="sort" (change)="isSort=!isSort" (change)="extendSelect.controls['sort'].setValue(isSort)">{{"Sort"|translate}}</mat-checkbox>


    </ng-container> -->
    
    <ng-container *ngIf="displayedInputs.includes('year')">
      <div style="max-width: 130px;">
        <!-- <app-year-dropdown formControlName="year" [controlName]="''" (changeValue)="extendSelect.get('year').patchValue($event)"></app-year-dropdown> -->
        <app-year-dropdown [controlName]="activeYear" (changeValue)="extendSelect.get('year').patchValue($event)"></app-year-dropdown>
      </div>
    </ng-container>
  </form>
<div *ngIf="chips.length > 0 && chips[0] != '' && showChips">
  <mat-form-field style="width: 100%">
    <mat-chip-list>
      <mat-chip *ngFor="let chip of chips">
        {{ chip | translate }}
      </mat-chip>
    </mat-chip-list>
  </mat-form-field>
</div>
