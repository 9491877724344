import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-info-dialog',
  styleUrls: ['./info-dialog.component.scss'],
  templateUrl: './info-dialog.component.html',
})
export class InfoDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {text: string}) { }

}
