<div class="d-flex justify-content-between mb-2 table-heading table-header-wrapper" style="
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
" [ngClass]="{'fromBot': fromBot}">
  <div class="head-box">
    <div class="search">
      <mat-form-field *ngIf="search" appearance="fill" class="search-form" style="width: 100%">
        <input matInput placeholder="{{ 'search' | translate }}" (keyup)="filterChanged.emit($event.target?.value)" />
        <i matSuffix class="fas fa-search"></i>
      </mat-form-field>
    </div>

    <div *ngIf="totalDebtGEL" class="d-flex align-items-center">
      <div class="">
        <h4 class="m-0">{{"totaldebtGEL" | translate}}: </h4>
      </div>
      <div class="">
        <h4 class="pl-2 m-0"> {{ totalDebtGEL }} </h4>
      </div>
    </div>

    
    <div *ngIf="totalDebtUSD" class="d-flex align-items-center" style="margin-left: 50px">
      <div class="">
        <h4 class="m-0">{{"totaldebtUSD" | translate}}: </h4>
      </div>
      <div class="">
        <h4 class="pl-2 m-0"> {{ totalDebtUSD }} </h4>
      </div>
    </div>

    <div class="filters" *ngIf="extendSelect">
      <app-extend-select [lang]="lang" [displayedInputs]="displayedInputs" (filterValue)="extendSelectChanged.emit($event)"
        [showChips]="showChips" [lecturers]="lecturers" [curationsList]="curations" [groups]="groups"
        [multiselect]="multiselect" [activeYear]="activeYear" [modules]="modules"></app-extend-select>
    </div>
    <form [formGroup]="filterForm" style="
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;" *ngIf="filters.length">
      <mat-form-field *ngIf="filters.includes('datePicker')" (click)="datePicker.open()"
        style="cursor: pointer; pointer-events: all" class="calendar" appearance="fill">
        <mat-label>{{ "date" | translate }}</mat-label>
        <input matInput (ngModelChange)="setValue()" [matDatepicker]="datePicker" [readonly]="true"
          formControlName="date" style="cursor: pointer" />
        <mat-datepicker-toggle matSuffix [for]="datePicker" class="icon" #calendar>
          <svg>
            <use [attr.xlink:href]="icon + 'calendar.svg#Layer_1'"></use>
          </svg>
        </mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('semester')">
        <mat-label>{{'semester' | translate}}</mat-label>
        <mat-select formControlName="semester" (closed)="setValue()" [multiple]="multiple">
          <mat-option [value]="''">{{
            "*" | translate
            }}</mat-option>
          <mat-option *ngFor="let semester of semesterEnum" [value]="semester">{{ semester }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('semester-multiple')">
        <mat-label>{{'semester' | translate}}</mat-label>
        <mat-select formControlName="semesterMultiple" (closed)="setValue()" [multiple]="true">
          <mat-option *ngFor="let semester of semesterEnum" [value]="semester">{{ semester }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('campus')">
        <mat-label>{{'campus' | translate}}</mat-label>
        <mat-select formControlName="campus" (closed)="setValue()" [multiple]="false">
          <mat-option [value]="''">{{
            "*" | translate
            }}</mat-option>
          <mat-option *ngFor="let campus of campusOptions" [value]="campus.value">{{ campus.display | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('sector')">
        <mat-label>{{'sector' | translate}}</mat-label>
        <mat-select formControlName="sector" (closed)="setValue()" [multiple]="false">
          <mat-option [value]="''">{{
            "*" | translate
            }}</mat-option>
          <mat-option *ngFor="let sector of sectors" [value]="sector.value">{{ sector.display | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('activity')">
        <mat-label>{{'activity' | translate}}</mat-label>
        <mat-select formControlName="activity" (closed)="setValue()" [multiple]="false">
          <mat-option [value]="activitiesList[1].value">{{
            activitiesList[1].title | translate
            }}</mat-option>
          <mat-option [value]="activitiesList[0].value">{{
            activitiesList[0].title | translate
            }}</mat-option>
            <!-- <mat-option [value]="''">{{
              "*" | translate
              }}</mat-option>
          <mat-option *ngFor="let act of activitiesList" [value]="act.value">{{ act.title | translate }}
          </mat-option> -->
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('program')">
        <mat-label>{{'program' | translate}}</mat-label>
        <mat-select formControlName="program" (closed)="setValue()" [multiple]="false">
          <mat-option [value]="''">{{
            "*" | translate
            }}</mat-option>
          <mat-option *ngFor="let program of programsList" [value]="program.value">{{ program.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('externalSubject')">
        <mat-label>{{'subject' | translate}}</mat-label>
        <mat-select formControlName="externalSubject" (closed)="setValue()" [multiple]="false">
          <mat-option [value]="''">{{
            "*" | translate
            }}</mat-option>
          <mat-option *ngFor="let externalSubjectOption of externalSubjectOptions" [value]="externalSubjectOption.value">{{ externalSubjectOption.title | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('recoveryGroups')">
        <mat-label>{{'recoveryGroups' | translate}}</mat-label>
        <mat-select formControlName="recoveryGroups" (closed)="setValue()" [multiple]="false">
            <mat-option [value]="1">{{
              'active-recovery' | translate
              }}</mat-option>
              <mat-option [value]="0">{{
                'block-recovery' | translate
                }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="drop-down" *ngIf="filters.includes('lecturer')">
        <app-dropdown [activeItem]="filterForm.get('lecturer').value?.name"
          [inputField]="true" [list]="lecturers" (valueChange)="setControlTo('lecturer', $event)" [scorer]="false"
          [placeholder]="'lecturer' | translate"></app-dropdown>
      </div>
      <div class="drop-down" *ngIf="filters.includes('groups')">
        <app-dropdown [activeItem]="filterForm.get('group').value?.name"
          [inputField]="true" (valueChange)="setControlTo('group', $event)" [list]="groups" [scorer]="false"
          [placeholder]="'group' | translate"></app-dropdown>
      </div>
      <app-input style="padding-bottom: 1.34375em;" *ngIf="filters.includes('curation')" formControlName="curation"
        [placeholder]="'curation' | translate" (keydown.enter)="setValue()" (blur)="setValue()"></app-input>
      <mat-form-field appearance="fill" *ngIf="filters.includes('flow')">
        <mat-label>{{'flow' | translate}}</mat-label>
        <mat-select formControlName="flow" (closed)="setValue()" [multiple]="multiple">
          <mat-option [value]="''">{{
            "*" | translate
            }}</mat-option>
          <mat-option *ngFor="let campus of flows" [value]="campus">{{ campus }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('groups-multiple')">
        <mat-label>{{'groups' | translate}}</mat-label>
        <mat-select formControlName="groups" [multiple]="true">
          <mat-option *ngFor="let campus of groups" [value]="campus.id" (click)="setValue()">{{ campus.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="filters.includes('roles')">
        <mat-label>{{'role' | translate}}</mat-label>
        <mat-select formControlName="selectedRole" (closed)="setValue()" [multiple]="false">
          <mat-option [value]="null">*</mat-option>
          <mat-option *ngFor="let role of roleList" [value]="role">{{ role.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="filters.includes('picker')" (click)="picker.open()"
        style="cursor: pointer; pointer-events: all" class="calendar" appearance="fill">
        <mat-label>{{ "startDate" | translate }}</mat-label>
        <input matInput (ngModelChange)="setValue()" [matDatepicker]="picker" [matDatepickerFilter]="dateFilter"
          [readonly]="true" formControlName="startDate" style="cursor: pointer" />
        <mat-datepicker-toggle matSuffix [for]="picker" class="icon" #calendar>
          <svg>
            <use [attr.xlink:href]="icon + 'calendar.svg#Layer_1'"></use>
          </svg>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div *ngIf="filters.includes('rangePicker')">
        <mat-form-field appearance="fill">
          <mat-label>{{'range-picker' | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="picker" separator="to" required [min]="today"
            [dateFilter]="dateFilterFn">
            <input matStartDate (ngModelChange)="setValue()" [placeholder]="'startDate' | translate"
              formControlName="start">
            <input matEndDate (ngModelChange)="setValue()" [placeholder]="'endDate' | translate" formControlName="end">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error>{{'required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </form>
    <div class="filters" *ngIf="extendSelect2">
      <app-extend-select [lang]="lang" [displayedInputs]="displayedInputs" (filterValue)="extendSelectChanged.emit($event)"
        [showChips]="showChips" [lecturers]="lecturers" [curationsList]="curations" [groups]="groups"
        [multiselect]="multiselect" [activeYear]="activeYear"></app-extend-select>
    </div>
  </div>
  <div style="
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  ">

    <button *ngIf="presureExportBtn" class="mr-2" (click)="exportPresure.emit()" mat-raised-button color="primary"
      style="width: fit-content; height: 57px">
      <i class="fas fa-file-excel mr-2" aria-hidden="true"></i>{{ 'export-presures' | translate }}
    </button>
    
    <button *ngIf="presureDatesBtn" class="mr-2" (click)="setConfiguration.emit()" mat-raised-button color="primary"
    style="width: fit-content; height: 57px">{{ 'presure-configuration' | translate }}
  </button>
  
  <button *ngIf="matMenu" class="mr-2" (click)="export()" mat-raised-button color="primary"
  style="width: fit-content; height: 57px">
  <i class="fas fa-file-excel mr-2" aria-hidden="true"></i>{{ "Export" | translate }}
  <div *ngIf="downloadInProgress" class="spinner-border text-secondary ml-2" role="status">
  </div>
</button>
<button *ngIf="salaryExportBtn" class="mr-2" (click)="salaryExport.emit()" mat-raised-button color="primary"
  style="width: fit-content; height: 57px">
  <i class="fas fa-file-excel mr-2" aria-hidden="true"></i>{{ 'salaries' | translate }}
</button>

    <button *ngIf="import" (click)="fileInput.click()" class="mr-2" mat-raised-button color="primary"
      style="width: fit-content; height: 57px">
      <i class="fas fa-file-excel mr-2" aria-hidden="true"></i>{{ "Import" | translate }}
    </button>

    <input #fileInput style="display: none" type="file" id="file" (change)="importFile.emit($event.target.files)"
      accept=".xlsx">

    <button *ngIf="exportBtn" class="mr-2" (click)="export()" mat-raised-button color="primary"
      style="width: fit-content; height: 57px">
      <i class="fas fa-file-excel mr-2" aria-hidden="true"></i>{{ 'Export' | translate }}
      <div *ngIf="downloadInProgress" class="spinner-border text-secondary ml-2" role="status">
      </div>
    </button>
    <button *ngIf="exportBtnForGroups && createMenu" [matMenuTriggerFor]="createMenu" class="mr-2" mat-raised-button color="primary"
      style="width: fit-content; height: 57px">
      <i class="fas fa-file-excel mr-2" aria-hidden="true"></i>{{ 'Export' | translate }}
      <div *ngIf="downloadInProgress" class="spinner-border text-secondary ml-2" role="status">
      </div>
    </button>

    <button *ngIf="matMenu; else regularButton" [matMenuTriggerFor]="matMenu" (click)="create()" mat-raised-button
      color="primary" style="width: fit-content; height: 57px">
      {{ "create" | translate }} / {{ "Import" | translate }}
    </button>
    <button *ngIf="createMenu && addBtn" [matMenuTriggerFor]="createMenu" (click)="create()" mat-raised-button
      color="primary" style="width: fit-content; height: 57px">
      {{ buttonTitle }}
    </button>
    <button (click)="markCorrespondence()" mat-raised-button color="primary" style="width: fit-content; height: 57px; margin-left: 15px; margin-bottom: 15px"
    *ngIf="multiArchive" [disabled]="elementsToMultiStatusChange.length !== 0 || elementsToMultiStatusChangeExternal.length !== 0">
     {{ 'closeGroups' | translate }}
   </button>
    <button (click)="markCorrespondence()" mat-raised-button color="primary" style="width: fit-content; height: 57px; margin-left: 15px; margin-bottom: 15px"
    *ngIf="multiUnArchive" [disabled]="elementsToMultiStatusChange.length !== 0 || elementsToMultiStatusChangeExternal.length !== 0">
     {{ 'unarchiveGroups' | translate }}
   </button>
   <button mat-raised-button color="primary" style="width: fit-content; height: 57px; margin-left: 15px; margin-bottom: 15px" *ngIf="(multiArchive || multiUnArchive) && startMultiStatusChange" [disabled]="elementsToMultiStatusChange.length === 0 && elementsToMultiStatusChangeExternal.length === 0" (click)="saveArchive()">
    {{ 'save' | translate }}
  </button>
    <button *ngIf="studyingStartDateBtn" (click)="studyingStartDateClicked.emit()" mat-raised-button
      color="primary" style="width: fit-content; height: 57px">
      {{ studyingStartDate }}
    </button>
    <button *ngIf="uploadQuizBtn && addUploadQuizBtn" [matMenuTriggerFor]="uploadQuizBtn" (click)="create()" mat-raised-button
      color="primary" style="width: fit-content; height: 57px">
      {{ uploadQuizBtnTitle }}
    </button>
    <button *ngIf="uploadQuizBtn && addUploadQuizBtn" (click)="uploadSystem()" mat-raised-button
      color="primary" style="width: fit-content; height: 57px">
      {{ "uploadSystem" | translate }}
    </button>
    <button *ngIf="createFilterBtn" (click)="createFilterBtnTitleClicked.emit(true)" mat-raised-button
      color="primary" style="width: fit-content; height: 57px">
      {{ createFilterBtnTitle }}
    </button>
    <ng-template #regularButton> 
      <button *ngIf="showBtn" [disabled]="buttonActiveFlag" (click)="create()" mat-raised-button color="primary"
        style="width: fit-content; height: 57px">
        {{ buttonTitle }}
      </button>
    </ng-template>
  </div>
</div>