import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../../../shared/components';
import {UserService} from "../user.service";

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordResetComponent implements OnInit {
  passwordForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    email: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    repeatePassword: new FormControl('', [Validators.required])
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: { id: any, email: any }, private snackBar: MatSnackBar,
              private dialog: MatDialogRef<PasswordResetComponent>, private service: UserService) { }

  ngOnInit(): void {
    this.getDetails();
    if (this.data.id) {
      this.passwordForm.get('id').patchValue(this.data.id);
    }
    if (this.data.email) {
      this.passwordForm.get('email').patchValue(this.data.email);
    }
  }


  saveData(): void {
    if (this.passwordForm.valid) {
      this.service.changePassword(this.passwordForm.value).subscribe(res => {
        this.openSnackBar('success', 'success');
        this.dialog.close();
      }, error => {
        this.openSnackBar('error', 'error');
      });
      return;
    }
    this.openSnackBar('fill_gaps', 'error');
  }

  getDetails(): void {
    this.service.getDetails().subscribe(res => {
      this.passwordForm.get('email').patchValue(res.email);
      this.passwordForm.get('id').patchValue(res.id);
    });
  }

  private openSnackBar(message: string, type: string): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        type,
        message
      },
      panelClass: type,
      duration: 3000,
    });
  }

}
