<mat-form-field class="formBox w-100" [appearance]="appearance" [ngStyle]="{width}">
  <input matInput [type]="type" (keyup)="changeInput.emit($event.target?.value)" [placeholder]="placeholder | translate" [matAutocomplete]="auto" name="exmaRec" #exmaRec="ngModel" [(ngModel)]="activeItem">
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete">
  <ng-container *ngIf="searchBox; else lookUp">
    <ng-container *ngFor="let item of items">
      <mat-option [title]="item.name" (click)="setValue.emit(item)" [value]="item.name">
        {{item.name}}
      </mat-option>
    </ng-container>
  </ng-container>
  <ng-template #lookUp>
    <ng-container *ngFor="let item of items">
      <mat-option [title]="item.name" *ngIf="item?.name?.toLowerCase().includes(activeItem.toLowerCase()) || !activeItem" (click)="setValue.emit(item)" [value]="item.name">
        {{item.name}}
      </mat-option>
    </ng-container>
  </ng-template>
</mat-autocomplete>
