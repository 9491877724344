<div class="modal-header">
  <h1 mat-dialog-title class="modal-title">
    {{ "password_change" | translate }}
  </h1>
  <app-dialog-close-icon mat-dialog-close></app-dialog-close-icon>
</div>

<div class="body">
  <form [formGroup]="passwordForm">
    <div>
      <div class="mb-10">
        <app-input formControlName="email" type="text" [readOnly]="true" label="{{ 'email' | translate }}"></app-input>
      </div>
      <div class="mb-10">
        <app-input formControlName="password" type="password" label="{{ 'oldPassword' | translate }}"></app-input>
      </div>
      <div class="mb-10">
        <app-input formControlName="newPassword" type="password" label="{{ 'new_password' | translate }}"></app-input>
      </div>
      <div class="mb-10">
        <app-input formControlName="repeatePassword" type="password" label="{{ 'repeatPassword' | translate }}"></app-input>
      </div>
    </div>
  </form>
  <button mat-raised-button color="primary" class="mr-5" (click)="saveData()">
    {{ "save" | translate }}
  </button>
</div>
