import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginResponse } from 'src/app/shared/interfaces';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  get isAuthenticated(): boolean {

    return !!this.currentUser?.token;

  }

  get currentUser(): any {

    return this.localStorageService.get('currentUser');

  }

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  login(email: string, password: string): Observable<LoginResponse> {

    return this.http.post('account', {
      email,
      password
    }).pipe(
      map((response: LoginResponse) => {
        if (response?.token) {
          this.localStorageService.set('currentUser', {
            token: response.token
          });
        }
        return response;
      })
    );

  }

  resetPassword(body): Observable<any> {
    return this.http.post('resetpassword/reset-password', body);
  }


  logout(): void {
    this.localStorageService.remove('currentUser');
    this.router.navigate(['auth/login']);
  }

  recoveryPass(body: { email: string }) {
    return this.http.post('resetpassword/forgot-password', body);
  }
}
