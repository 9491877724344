import { Directive, OnDestroy } from '@angular/core';
import { Subscription, SubscriptionLike } from 'rxjs';

@Directive()
export class BaseSubscriptionClass implements OnDestroy {

    subscriptions: (() => void)[] = [];
    subscriptions$: (Subscription | SubscriptionLike)[] = [];

    ngOnDestroy(): void {

        this.subscriptions.forEach((unsubscribe: (() => void)) => {

            unsubscribe();

        });

        this.subscriptions$.forEach((subscription: Subscription) => {

            subscription.unsubscribe();

        });

    }

}
