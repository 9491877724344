import { SheetsService } from '../../../group-sheets/sheets.service';
import { ScorerComponent } from './../../../group-sheets/scorer/scorer.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-lect-switcher',
  templateUrl: './lect-switcher.component.html',
  styleUrls: ['./lect-switcher.component.scss']
})
export class LectSwitcherComponent implements OnInit {
  lecturers: any[] = [];
  allowedLecturers: any[] = [];
  columns = [
    {
      column: 'name',
      title: 'name'
    },
    {
      column: 'deleteAttribute',
      title: ''
    },
  ];


  constructor(
    private service: SheetsService,
    private dialog: MatDialogRef<ScorerComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.service.getLecturersList().subscribe(res => {
      this.lecturers = res.records.map(vals => {
        return {
          name: vals.firstNameGeo + ' ' + vals.lastNameGeo,
          value: vals.entityID
        };
      });
    });
    this.allowedLecturers = this.data.lecturers.map(vals => {
      return {
        name: vals.firstNameGeo + ' ' + vals.lastNameGeo,
        value: vals.lecturerId
      };
    });
  }

  searchValue(val): void {
    const body = {
      allowedLecturerId: val.value,
      ...this.data.info
    };
    if(this.data.isExternal) {
      this.service.setExternalLecturer(body).subscribe(() => {
        this.allowedLecturers.push(val);
      });
    } else {
      this.service.setLecturer(body).subscribe(() => {
        this.allowedLecturers.push(val);
      });
    }
  }

  remove(item, index): void {
    const body = {
      AllowedLecturerId: item.value,
      ...this.data.info
    };
    if(this.data.isExternal) {
      this.service.removeExternalLecturer(body).subscribe(() => {
        this.allowedLecturers.splice(index, 1);
      });
    } else {
      this.service.removeLecturer(body).subscribe(() => {
        this.allowedLecturers.splice(index, 1);
      });
    }
  }

}
