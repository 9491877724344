 import { Injectable } from '@angular/core';
 import { throwError } from 'rxjs';
 import { Observable } from 'rxjs/internal/Observable';
 import { catchError } from 'rxjs/operators';
 import { AuthService } from '../services';
 import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
  } from '@angular/common/http';

 @Injectable({
    providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const isAuthenticated = this.authenticationService.isAuthenticated;
        const userToken = this.authenticationService.currentUser?.token;

        if (isAuthenticated && userToken) {
            req = req.clone({
                setHeaders: {
                Authorization: `Bearer ${userToken}`,
                },
            });
        }

        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.authenticationService.logout();
                }
                return throwError(err);
            })
        );
    }
}
