<div class="modal-header">
  <h1 class="modal-title">
    <ng-container *ngIf="!data.schedule; else editCuration">
      {{ "addSchedule" | translate }}
    </ng-container>
    <ng-template #editCuration>
      {{ "editSchedule" | translate }}

    </ng-template>
  </h1>
  <app-dialog-close-icon  mat-dialog-close ></app-dialog-close-icon>
</div>

<ng-container *ngIf="!loader; else loaderOnSave">
<mat-dialog-content>
  <form [formGroup]="addForm" (ngSubmit)="sendData()">
    <app-input formControlName="curation" [placeholder]="'curation' | translate"></app-input>
    <app-input formControlName="base" [placeholder]="'base' | translate"></app-input>
    <app-input formControlName="address" [placeholder]="'address' | translate"></app-input>
    <app-input formControlName="subGroupNumber" [placeholder]="'subGroupNumber' | translate" [type]="'number'" [min]="0"></app-input>
    <div class="drop-down">
      <app-dropdown
        [activeItem]="addForm.get('lecturer').value"
        [inputField]="true"
        [list]="lecturers"
        [height]="'45px'"
        (valueChange)="changeInput('lecturer', $event)"
        [scorer]="false"
        [placeholder]="'lecturer' | translate"
      ></app-dropdown>
    </div>
    <div class="drop-down">
      <app-dropdown
        [activeItem]="addForm.get('group').value"
        [height]="'45px'"
        [inputField]="true"
        [list]="groups"
        (valueChange)="changeInput('group', $event)"
        [scorer]="false"
        [placeholder]="'group' | translate"
      ></app-dropdown>
    </div>
    <div class="drop-down">
      <app-dropdown
        [activeItem]="addForm.get('audience').value"
        [height]="'45px'"
        [inputField]="true"
        [list]="audiences"
        (valueChange)="changeInput('audience', $event)"
        [scorer]="false"
        [placeholder]="'audience' | translate"
      ></app-dropdown>
    </div>
    <mat-form-field (click)="datePicker.open()" style="cursor: pointer; pointer-events: all" class="calendar" appearance="fill">
      <mat-label>{{ "date" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="datePicker"
        [readonly]="true"
        formControlName="date"
        style="cursor: pointer"
      />
      <mat-datepicker-toggle matSuffix [for]="datePicker" class="icon" #calendar>
        <svg>
          <use [attr.xlink:href]="icon + 'calendar.svg#Layer_1'"></use>
        </svg>
      </mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{'type' | translate}}</mat-label>
      <mat-select formControlName="type" [multiple]="false">
        <mat-option
          *ngFor="let group of types"
          [value]="group.value"
        >{{ group.display | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{'hours-rate' | translate}}</mat-label>
      <mat-select formControlName="hourCount" [multiple]="false">
        <mat-option
          *ngFor="let group of [1,2,3,4,5,6,7,8,9]"
          [value]="group"
        >{{ group }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{'hour' | translate}}</mat-label>
      <mat-select formControlName="hour" [multiple]="false">
        <mat-option
          *ngFor="let group of hours"
          [value]="group"
        >{{ group }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="save-btn"
    >
      {{ "save" | translate }}
    </button>
  </form>
</mat-dialog-content>
</ng-container>

<ng-template #loaderOnSave>
  <div style="text-align: center; margin-top: 10px;">
    <h1>{{"pleaseWait" | translate}}</h1>
  <app-loader></app-loader>
</div>
</ng-template>