<div class="snackbar-msg-content ">
    <div class="snackbar-message">
      <div class="snackbar-wrapper " >


        <svg *ngIf="data.type=='success'" class="snackbar-svg-icon" focusable="false" viewBox="0 0 24 24"
          aria-hidden="true" role="presentation">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z">
          </path>
        </svg>

        <svg *ngIf="data.type=='error'" class="snackbar-svg-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
          role="presentation">
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z">
          </path>
        </svg >
        <span>{{message}}</span>
      </div>
    </div>
    <div class="snackbar-action">
      <button class="snackbar-btn-root" tabindex="0" type="button" aria-label="Close" (click)="dismissSnackbar();">
        <span class="snackbar-label">
          <svg class="snackbar-svg-icon " focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
            </path>
            <path fill="none" d="M0 0h24v24H0z"></path>
          </svg>
        </span>
        <span class="snackbar-touch-root"></span>
      </button>
    </div>
  </div>
