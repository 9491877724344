import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import {
  AddIconComponent,
  ChangeLanguageComponent,
  EditIconComponent,
  InfoDialogComponent,
  InputComponent,
  SnackBarComponent,
  TableComponent,
  TableHeaderComponent,
  TrashIconComponent,
  DialogCloseIconComponent

} from './components';
import { ExtendSelectComponent } from './components/extend-select/extend-select.component';
import { MoreVertComponent } from './components/icons/more-vert/more-vert.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LoaderComponent } from './components/loader/loader.component';
import { YearDropdownComponent } from './components/year-dropdown/year-dropdown.component';
import { StickyHeaderDirective } from './sticky-header.directive';
import { PresurePopupComponent } from './presure-popup/presure-popup.component';
import { PresureConfigurationComponent } from './presure-configuration/presure-configuration.component';
import { DynamicLookupComponent } from './dynamic-lookup/dynamic-lookup.component';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { PickBetweenTwoDatesComponent } from './components/pick-between-two-dates/pick-between-two-dates.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { SalariesExportComponent } from './salaries-export/salaries-export.component';
import { ChancelleryDynamicLookupComponent } from './chancellery-dynamic-lookup/chancellery-dynamic-lookup.component';
import { SpinnerComponent } from './components/spinner/spinner.component';


const MATERIAL_IMPORTS = [
  MatInputModule,
  MatButtonModule,
  MatDividerModule,
  MatCheckboxModule,
  MatCardModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatSelectModule,
  MatDialogModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatListModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatAutocompleteModule,
  MatBottomSheetModule,
  MatChipsModule,
  MatSnackBarModule,
  MatRippleModule,
  MatTooltipModule,
  MatBadgeModule,
  MatTabsModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatIconModule,
  MatButtonToggleModule,
  MatGridListModule

];

const SHARED_MODULES = [
  CommonModule,
  RouterModule,
  TranslateModule,
  NgbModule,
  ReactiveFormsModule,
];

const SHARED_COMPONENTS = [
  TableComponent,
  TableHeaderComponent,
  SnackBarComponent,
  InputComponent,
  AddIconComponent,
  TrashIconComponent,
  DialogCloseIconComponent,
  EditIconComponent,
  InfoDialogComponent,
  ChangeLanguageComponent,
  ExtendSelectComponent,
  PickBetweenTwoDatesComponent,
  AlertComponent
];

@NgModule({
  declarations: [...SHARED_COMPONENTS, MoreVertComponent, DropdownComponent, LoaderComponent, SpinnerComponent, YearDropdownComponent, StickyHeaderDirective, PresurePopupComponent, SalariesExportComponent, PresureConfigurationComponent, DynamicLookupComponent, ChancelleryDynamicLookupComponent],
  providers: [],
  imports: [...SHARED_MODULES, ...MATERIAL_IMPORTS, FormsModule],
  exports: [...SHARED_MODULES, ...MATERIAL_IMPORTS, ...SHARED_COMPONENTS, DropdownComponent, LoaderComponent, SpinnerComponent, YearDropdownComponent, StickyHeaderDirective, DynamicLookupComponent, ChancelleryDynamicLookupComponent],
})
export class SharedModule { }
