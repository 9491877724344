import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-vert',
  templateUrl: './more-vert.component.html',
  styleUrls: ['./more-vert.component.scss']
})
export class MoreVertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
