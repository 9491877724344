import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageHelperService } from 'src/app/core/services';
import { ChancelleryService } from 'src/app/features/chancellery/chancellery.service';
import { iconPath } from 'src/utils';

@Component({
  selector: 'app-external-statements',
  templateUrl: './external-statements.component.html',
  styleUrls: ['./external-statements.component.scss']
})
export class ExternalStatementsComponent implements OnInit {
  icon: string = iconPath;
  subscribe: any;
  activeButton: number = 0;
  currentDate: any;
  statementText: string = null;
  files: any[] = [];
  papersCount: number = null;
  idNumber: string = null;
  firstName: string = null;
  lastName: string = null;
  email: string = null;
  address: string = null;
  phone: string = null;
  lang: string;

  constructor(private router: Router, private service: ChancelleryService, private languageHelper: LanguageHelperService) {
    this.getActiveButton();
    this.lang = this.languageHelper.currentLanguageCode;
   }

  ngOnInit(): void {
    this.subscribe = {
      closed: true
    }
    this.getCurrentDate();
  }

  getActiveButton() {
    switch (this.router.url.split('/')[2]) {
      case 'citizen':
        this.activeButton = 0;
        break;
      case 'admission':
        this.activeButton = 1;
        break;
      default:
        this.activeButton = 0;
        break;
    }
  }

  getCurrentDate() {
    this.currentDate = new Date();
  }
  
  onTemplateTextInput(text: string) {
    this.statementText = text;
  }

  handleFileInput(file: any) {
    this.files.push(file.target.files.item(0));
    file.target.value = '';
  }

  onRemoveFile(index: number) {
    this.files.splice(index, 1);
  }

  setPapersCount(papersCount: number) {
    this.papersCount = papersCount;
  }

  setInfo(type: string, value: string) {
    switch (type) {
      case 'firstName':
        this.firstName = value;
        break;
      case 'lastName':
        this.lastName = value;
        break;
      case 'identityNumber':
        this.idNumber = value;
        break;
      case 'email':
        this.email = value;
        break;
      case 'address':
        this.address = value;
        break;
      case 'phone':
        this.phone = value;
        break;
      default:
        this.firstName = null;
        this.lastName = null;
        this.idNumber = null;
        this.email = null;
        this.address = null;
        this.phone = null;
        break;
    }
  }

  setEmail(value: string) {
    this.email = value;
  }
  setAddress(value: string) {
    this.address = value;
  }

  sendOutsideTemplate() {
    const payload = {
      title: this.activeButton === 0 ? 'უცხო პირი' : 'აბიტურიენტი',
      description: this.statementText,
      firstName: this.firstName,
      lastName: this.lastName,
      idNumber: this.idNumber,
      mobNumber: this.phone,
      address: this.address,
      type: this.activeButton === 0 ? 10 : 9,
      email: this.email,
      // papersCount: this.papersCount,
      files: this.files 
    }

    const formData = new FormData();
    
    Object.keys(payload).forEach(key => {
      if(payload[key] ?? null) {
        if(key === 'files') {  
          payload.files.forEach((file) => {
            formData.append('files', file);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }
    });
    
    this.subscribe = this.service.sendOutsideTemplate(formData).subscribe(res => {
      this.router.navigate(['/auth/login']);
    })
  }
}
