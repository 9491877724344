import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BaseSubscriptionClass } from '../../classes';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
        multi: true,
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputComponent)
    }
  ],
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent extends BaseSubscriptionClass implements ControlValueAccessor {

  @Input() label = '';
  @Input() placeholder = '';

  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective;
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Input() type = 'text';
  @Input() min: number | string;
  @Input() readOnly = false;
  @Input() validationChecker: boolean = false;

  @Output()
  keyUp: EventEmitter<string> = new EventEmitter<string>();

  keyUp$ = new Subject<string>();

  get control(): FormControl | AbstractControl {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  constructor(private controlContainer: ControlContainer) {
    super();

    this.subscriptions$.push(
      this.keyUp$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe(val => {
        this.keyUp.emit(val);
      })
    );
  }

  writeValue(value: string): void {

    this.formControlDirective.valueAccessor.writeValue(value);

  }

  registerOnChange(fn: any): void {

    this.formControlDirective.valueAccessor.registerOnChange(fn);

  }
  registerOnTouched(fn: any): void {

    this.formControlDirective.valueAccessor.registerOnTouched(fn);

  }

  onKeyUp(text: string): void {
    this.keyUp$.next(text);
  }
}
