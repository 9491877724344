<div
  class="container position-relative d-flex justify-content-center align-items-center"
  tabindex="-1"
  #listBox
  [ngStyle]="{ height: height }"
  (blur)="close($event)"
  [ngClass]="{ disabled: disabled, bordered: !inputField, scorer: scorer, altered: altered }"
>
<div class="position-absolute mosce" *ngIf="mosceScore">
  {{mosceScore}}
</div>
  <input
    type="text"
    *ngIf="inputField"
    [placeholder]="placeholder"
    [(ngModel)]="activeItem"
    (ngModelChange)="searchInput(activeItem)"
    (click)="setOpener(false)"
    (input)="visible = true"
    (blur)="close($event)"
  />
  <div class="close-icon" *ngIf="inputField && activeItem" (click)="activeItem = ''; searchInput(activeItem)">
    <img [src]="icon + 'close-button.svg'" alt="close">
  </div>
  <div class="input" *ngIf="!inputField" (click)="setOpener()">
    <span>
      {{ activeItem || "" }}
    </span>
    <span class="icon" *ngIf="activeItem">
      <svg [ngClass]="{ 'opened-icon': visible }">
        <use [attr.xlink:href]="icon + 'arrow-down.svg#Layer_1'"></use>
      </svg>
    </span>
  </div>
  <div class="list" *ngIf="visible && list.length" [ngClass]="{'reversed': reversed && !inputField}">
    <ng-container *ngFor="let item of list">
      <span
        *ngIf="item !== null && (item?.name?.toLowerCase().includes(activeItem) || !activeItem || !inputField)"
        (click)="setActive(item)"
      >
        <ng-container *ngIf="prefix; else list">
          {{ prefix + item.name | translate }}
        </ng-container>
        <ng-template #list>
          {{ item.name }}
        </ng-template>
      </span>
    </ng-container>
  </div>
</div>
