import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { AuthService } from 'src/app/core/services';
import { LayoutService } from '../../services';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { iconPath } from '../../../../../../utils';
import { ChancelleryService } from 'src/app/features/chancellery/chancellery.service';
import { Router } from '@angular/router';

interface FoodNode {
  name: string;
  children?: FoodNode[];
  permission: string;
  navigate: string;
}

interface MenuNodes {
  expandable: boolean;
  name: string;
  level: number;
  permission: string;
  navigate: string;
}

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent implements OnInit, AfterViewInit {
  constructor(
    private layout: LayoutService,
    private loc: Location,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private chancService: ChancelleryService,
    private router: Router,
  ) {
    this.dataSource.data = this.leveledMenu;
  }

  @Output() toggleMenu: EventEmitter<any> = new EventEmitter<any>();
  disableAsideSelfDisplay: boolean;
  headerLogo: string;
  icon: string = iconPath;
  brandSkin: string;
  ulCSSClasses: string;
  location: Location;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = false;
  showLogo = true;
  roles = [];

  leveledMenu = [
    {
      name: 'University',
      children: [
        { name: 'Apple', permission: 'fruits', navigate: '/apple' },
        { name: 'Banana', permission: null, navigate: '/apple' },
        { name: 'Fruit loops', permission: 'ScheduleView', navigate: '/apple' },
      ],
      permission: null,
      navigate: null,
    },
  ];

  treeControl = new FlatTreeControl<MenuNodes>(
    (node) => node.level,
    (node) => node.expandable
  );

  menuItems = [
    {
      url: '/home',
      text: 'Main',
    },
    {
      text: 'teaching_plan',
      children: [
        {
          url: '/curations',
          text: 'Curations',
          permission: 'CurationsView',
        },
        {
          url: '/external-subjects',
          text: 'External Subjects',
          permission: 'ExternalSubjectsView',
        },
        {
          url: '/modules',
          text: 'Modules',
          permission: 'ModulesView',
        },
        {
          url: '/subjects',
          text: 'Subjects',
          permission: 'SubjectsView',
        },
        {
          url: '/systems',
          text: 'systems',
          permission: 'SubjectsView',
        },
      ],
      visible: true,
      expanded: false,
      permission:
        'SubjectsView' ||
        'ModulesView' ||
        'ExternalSubjectsView' ||
        'CurationsView',
    },
    {
      url: '/sheets',
      text: 'Journals',
      permission: 'ViewAllRegisters' && 'ViewRegistersForLecturer',
    },
    {
      url: '/resources',
      text: 'resources',
      permission: 'ViewResources',
    },
    {
      url: '/finances',
      text: 'Finances',
      permission: 'ViewStudentFinances',
    },
    {
      url: '/chancellery',
      text: 'Chancellery',
      permission: 'ViewChancellery',
    },
    // {
    //   url: '/finances/details',
    //   text: 'Finances',
    //   permission: 'ViewStudentFinances',
    // },
    {
      text: 'Students',
      permission: 'ViewStudents',
      children: [
        {
          url: '/students',
          text: 'entireList',
          permission: 'ViewStudents',
        },
        {
          url: '/students/entire-scores',
          text: 'entireScores',
          permission: 'ViewStudents',
        }
      ],
    },
    {
      url: '/lecturers',
      text: 'Lecturers',
      permission: 'ViewLecturers',
    },
    {
      text: 'schedule',
      children: [
        {
          url: '/schedule',
          text: 'main_schedule',
          permission: 'ViewSchedule',
        },
        {
          url: '/audiences',
          text: 'audiences',
          permission: 'ViewAudiences',
        },
        {
          url: '/schedule-data',
          text: 'schedule_data',
          permission: 'UpdateSchedule',
        },
        {
          url: '/personal-schedule',
          text: 'personal_schedule',
          permission: 'ViewLecturerSchedule',
        },
        {
          url: '/schedule/groups-view',
          text: 'groups',
          permission: 'UpdateSchedule',
        },
      ],
      visible: true,
      expanded: false,
      permission:
        'ViewSchedule' ||
        'ViewAudiences' ||
        'ViewLecturerSchedule' ||
        'UpdateSchedule',
    },
    // {
    //   url: '/schedule',
    //   text: 'schedule',
    //   permission: 'ViewSchedule',
    // },
    {
      url: '/fill-groups',
      text: 'fillGroups',
      permission: 'GroupFormation',
    },
    {
      text: 'scoreConfiguration',
      permission: 'ViewEvaluationConfiguration',
      children: [
        {
          url: '/score-configuration',
          text: 'moduleScore',
          permission: 'ViewEvaluationConfiguration'
        },
        {
          url: '/score-configuration/external',
          text: 'externalModuleScore',
          permission: 'ViewEvaluationConfiguration'
        }
      ]
    },
    // {
    //   url: '/audiences',
    //   text: 'audiences',
    //   permission: 'ViewAudience',
    // },
    {
      url: '/groups',
      text: 'groups',
      permission: 'GroupsView',
    },
    {
      url: '/lecturer-score',
      text: 'lecturer-score',
      permission: 'GroupsView',
    },
    {
      text: 'Library',
      children: [
        {
          url: '/library/books',
          text: 'Books',
          permission: 'ViewBooks',
        },
        {
          url: '/library/users',
          text: 'Cards',
          permission: 'ViewCards',
        },
        {
          url: '/library/register-types',
          text: 'register-types',
          permission: 'ConfigureLibrary',
        },
        {
          url: '/library/fond-collections',
          text: 'fond-collections',
          permission: 'ConfigureLibrary',
        },
        {
          url: '/library/reports',
          text: 'reports',
          permission: 'ConfigureLibrary',
        },
        // {
        //   url: '/library/book-registers',
        //   text: 'book-registers',
        //   permission: 'CurationsView',
        // }
      ],
      visible: true,
      expanded: false,
      permission:
        'ViewBooks' || 'ViewCards' || 'ConfigureLibrary' || 'ConfigureLibrary',
    },
    {
      url: '/roles',
      text: 'roles',
      permission: 'RolesView',
    },
    {
      url: '/users',
      text: 'users',
      permission: 'UsersView',
    },
  ];

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  transformer(node: FoodNode, level: number): any {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      permission: node.permission,
      navigate: node.navigate,
      level,
    };
  }
  hasChild = (_: number, node: MenuNodes) => node.expandable;

  unreadChancelleryNotifications: number = 0;

  ngOnInit(): void {
    this.roles = JSON.parse(localStorage.getItem('actions'));
    const userRoles = JSON.parse(localStorage.getItem('userRoles'));

    if (userRoles.includes('Admin')) {
      this.menuItems[2].permission = 'ViewAllRegisters';
      this.menuItems.shift();
    }
    if (JSON.parse(localStorage.getItem('userRoles')).indexOf('Student') > -1) {
      let menuList = [];
      menuList = this.menuItems;
      menuList.push(
        {
          url: '/student-score',
          text: 'studentCard',
        },
        {
          url: '/student-score/score-report',
          text: 'scoreReport',
        }
      );
      this.menuItems = menuList;
    }
    if (
      JSON.parse(localStorage.getItem('userRoles')).indexOf('Lecturer') > -1
    ) {
      this.menuItems[2].permission = 'ViewRegistersForLecturer';
    }
    if (
      !userRoles.includes('Student') &&
      !userRoles.includes('Lecturer') &&
      this.menuItems[0].text === 'Main'
    ) {
      this.menuItems.shift();
    }
    this.disableAsideSelfDisplay =
      this.layout.getProp('aside.self.display') === false;
    this.brandSkin = this.layout.getProp('brand.self.theme');
    this.headerLogo = this.getLogo();
    this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
    this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown')
      ? '1'
      : '0';
    this.brandClasses = this.layout.getProp('brand');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
    this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
    this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${
      this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''
    }`;
    // this.role = JSON.parse(localStorage.getItem('roles')).roles;
    // Routing
    this.location = this.loc;
    if (this.router.url.split('/')[1] !== 'chancellery') {
      this.getChancelleryNotifications();
    }
  }

  ngAfterViewInit(): void {
    this.checkToShowLogo();
  }

  getChancelleryNotifications() {
    if (JSON.parse(localStorage.getItem('userRoles'))[0] === 'Admin') {
      return this.chancService.getUnopened().subscribe((res) => {
        this.unreadChancelleryNotifications = res.total;
      });
    } else if (
      JSON.parse(localStorage.getItem('userRoles'))[0] !== 'Admin' &&
      JSON.parse(localStorage.getItem('userRoles'))[0] !== 'Student'
    ) {
      return this.chancService.getForwardedUnopened().subscribe((res) => {
        this.unreadChancelleryNotifications = res.total;
      });
    } else {
      this.unreadChancelleryNotifications = 0;
    }
  }

  forChancelleryNotification(activeNav: string) {
    if (activeNav === 'Chancellery') {
      this.unreadChancelleryNotifications = 0;
    } else {
      this.getChancelleryNotifications();
    }
  }

  checkToShowLogo(): void {
    this.showLogo = !this.document.body.classList.contains('aside-minimize');
  }

  logout(): void {
    this.authService.logout();
  }

  // openUserInfo(): void {
  //   this.toggleMenu.next();
  // }

  expandItem(item): void {
    if (item.children) {
      item.expanded = !item.expanded;
    }
  }

  private getLogo(): string {
    if (this.brandSkin === 'light') {
      return './assets/media/logos/logo-dark.png';
    } else {
      return './assets/media/logo-light.png';
    }
  }
}
