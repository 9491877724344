import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-close-icon',
  templateUrl: './dialog-close-icon.component.html',
  styleUrls: ['./dialog-close-icon.component.scss']
})
export class DialogCloseIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
