import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { BaseSubscriptionClass } from '../../classes';
import { FormControl, FormGroup } from '@angular/forms';
import { SEMESTER } from '../../constants/dropdowns/semester';
import { iconPath, stringToDate } from '../../../../utils';
import { Campus, StudingLanguage } from '../../constants';
import { HttpClient } from '@angular/common/http';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent extends BaseSubscriptionClass implements OnInit, AfterViewInit {

  // @ViewChild('input', { static: true }) input: ElementRef;
  @Output() createClicked: EventEmitter<void> = new EventEmitter();
  @Output() setValueToFilter: EventEmitter<any> = new EventEmitter();
  @Output() setConfiguration: EventEmitter<any> = new EventEmitter();
  @Output() filterChanged: EventEmitter<string> = new EventEmitter();
  @Output() extendSelectChanged: EventEmitter<string> = new EventEmitter();
  @Output() exportPresure: EventEmitter<string> = new EventEmitter();
  @Output() salaryExport: EventEmitter<string> = new EventEmitter();
  @Output() studyingStartDateClicked: EventEmitter<string> = new EventEmitter();
  @Output() uploadSystemClicked: EventEmitter<void> = new EventEmitter();
  @Input() matMenu: MatMenu;
  @Input() exportBtn = false;
  @Input() exportBtnForGroups = false;
  @Input() rangeDates = false;
  @Input() presureDatesBtn = false;
  @Input() showChips = false;
  @Input() displayedInputs: string[] = [];
  @Input() extendSelect = false;
  @Input() extendSelect2 = false;
  @Input() createMenu: MatMenu;
  @Input() multiselect = true;
  @Input() showBtn = true;
  @Input() flows = [];
  @Input() presureExportBtn = false;
  @Input() addBtn = true;
  @Input() addUploadQuizBtn = false;
  @Input() search = true;
  @Input() buttonActiveFlag = false;
  @Input() buttonTitle = '';
  @Input() filters = [];
  @Input() roleList: any = [];
  @Input() multiple = true;
  @Input() lecturers = [];
  @Input() groups = [];
  @Input() splited = false;
  @Input() import = false;
  @Input() subjects = [];
  @Input() modules = [];
  @Input() lang: string = 'Geo';
  @Input() curations = [];
  @Input() fromBot = true;
  @Input() totalDebtGEL!: number;
  @Input() totalDebtUSD!: number;
  @Input() salaryExportBtn: boolean = false;
  @Input() uploadQuizBtnTitle: string;
  @Input() uploadQuizBtn: MatMenu;
  @Input() studyingStartDateBtn: boolean = false;
  @Input() studyingStartDate: string;
  @Input() createFilterBtn: boolean;
  @Input() createFilterBtnTitle: string;
  @Input() activeYear: string;
  @Input() downloadInProgress: boolean = false;
  @Input() startMultiStatusChange: boolean = false;
  @Input() elementsToMultiStatusChange: any;
  @Input() elementsToMultiStatusChangeExternal: any;
  @Input() multiArchive: boolean = false;
  @Input() multiUnArchive: boolean = false;

  @Output() createFilterBtnTitleClicked: EventEmitter<boolean> = new EventEmitter();
  @Output() exportClicked: EventEmitter<void> = new EventEmitter();
  @Output() filterValue: EventEmitter<void> = new EventEmitter();
  @Output() importFile: EventEmitter<any> = new EventEmitter();
  @Output() markCorrespondenceClicked: EventEmitter<void> = new EventEmitter();
  @Output() urchiveGroups: EventEmitter<void> = new EventEmitter();
  @Output() urchiveGroupsExternal: EventEmitter<void> = new EventEmitter();

  icon: string = iconPath;
  campusOptions = [{ display: 'Rustavi', value: Campus.Rustavi }, { display: 'Tbilisi', value: Campus.Tbilisi }];
  sectors = [{ display: 'English', value: StudingLanguage.English }, { display: 'Georgian', value: StudingLanguage.Georgian }];
  filterForm: FormGroup = this.createForm();
  semesterEnum = SEMESTER;
  activitiesList = [{ title: 'archived', value: 1 }, { title: 'active', value: 0 }];
  programsList = [{ title: 'MD', value: 0 }, { title: 'PBL', value: 1 }];
  externalSubjectOptions = [{ title: 'moduleCommonSubjects', value: false }, { title: 'externalSubjects', value: true }];

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.rangeDates) {
      this.httpClient.get(`lecturers/hours_dates`).subscribe((res: any) => {
        this.filterForm.get('start').setValue(stringToDate(res.fromDate.split('T')[0]));
        this.filterForm.get('end').setValue(stringToDate(res.toDate.split('T')[0]));
      });
    }
    // this.roleList = [];
  }

  createForm(): FormGroup {
    return new FormGroup({
      semester: new FormControl(''),
      semesterMultiple: new FormControl(['1', '2', '3', '4', '5']),
      selectedRole: new FormControl(''),
      startDate: new FormControl(null),
      campus: new FormControl(''),
      activity: new FormControl(''),
      program: new FormControl(''),
      sector: new FormControl(''),
      flow: new FormControl(''),
      date: new FormControl(''),
      curation: new FormControl(''),
      lecturer: new FormControl({ name: '', value: '' }),
      group: new FormControl({ name: '' }),
      groups: new FormControl([]),
      start: new FormControl(null),
      end: new FormControl(null),
      recoveryGroups: new FormControl(1),
      externalSubject: new FormControl('')
    });
  }

  ngAfterViewInit(): void {

    // this.subscriptions$.push(
    //   fromEvent(this.input.nativeElement, 'keyup')
    //     .pipe(
    //       filter(Boolean),
    //       debounceTime(300),
    //       distinctUntilChanged(),
    //       tap(() => {
    //         this.filterChanged.next(this.input.nativeElement.value);
    //       })
    //     )
    //     .subscribe()
    // );

  }

  setValue(): void {
    this.filterValue.emit(this.filterForm.value);
  }

  setControlTo(type, event): void {
    this.filterForm.get(type).patchValue(event);
    this.filterValue.emit(this.filterForm.value);
  }

  dateFilter: (date: Date | null) => boolean =
    (date: Date | null) => {
      const day = date?.getDay();
      return day === 1;
    }

  create(): void {
    this.createClicked.next();

  }
  uploadSystem(): void {
    this.uploadSystemClicked.next();
  }

  export(): void {
    this.exportClicked.next();
  }

  markCorrespondence(): void {
    if (this.elementsToMultiStatusChange.length === 0) {
      this.markCorrespondenceClicked.next();
    }
  }

  saveArchive() {
    if (this.elementsToMultiStatusChange.length > 0) {
      this.urchiveGroups.next();
    }
    if (this.elementsToMultiStatusChangeExternal.length > 0) {
      this.urchiveGroupsExternal.next();
    }
  }
}
