export enum Campus
    {
        Rustavi="Rustavi",
        Tbilisi="Tbilisi"
    }

export enum CampusType {
  Rustavi,
  Tbilisi
}
