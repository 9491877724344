import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {dateToString} from '../../../utils';

@Component({
  selector: 'app-presure-popup',
  templateUrl: './presure-popup.component.html',
  styleUrls: ['./presure-popup.component.scss']
})
export class PresurePopupComponent implements OnInit {
  presureForm: FormGroup = new FormGroup({
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required)
  });
  loader = false;
  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  exportData(): void {
    this.loader = true;
    this.httpClient.get(`lecturers/export_hours?fromDate=${dateToString(this.presureForm.value.fromDate)}&toDate=${dateToString(this.presureForm.value.toDate)}`, { ...{ responseType: 'arraybuffer', observe: 'response' } }).subscribe((res: any) => {
      this.loader = false;
      const type = res.headers.get('Content-Type');
      // download file with name
      const blob = new Blob([res.body], { type: 'xlsx' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'lecturers.xlsx';
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }, (error)=> {
      this.loader = false;
    });
  }

}
