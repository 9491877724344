export const iconPath = 'assets/svg/';

export function addDays(value: Date, days: number): Date {
    const date = new Date(value);
    date.setDate(date.getDate() + days);
    return date;
}

export function removeDate(value: Date, days: number): Date {
    const date = new Date(value);
    date.setDate(date.getDate() - days);
    return date;
}

export function dateToString(date: Date): string {
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    const day = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
    const value = date.getFullYear() + '-' + month + '-' + day;
    return value;
}

export function stringToDate(date: string): Date {
    const str = date + 'T00:00:00';
    return new Date(str + 'Z');
}

export function getMonday(d): Date {
    d = new Date(d);
    const day = d?.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
}



