<app-change-language></app-change-language>
<div class="external-statements">
  <div class="go-back" [routerLink]="'/auth/login'">
    <div class="icon">
      <svg>
        <use [attr.xlink:href]="icon + 'go-back.svg#Layer_1'"></use>
      </svg>
    </div>
    <span>
      {{ "back" | translate }}
    </span>
  </div>
  <div class="template-buttons">
    <button [ngClass]="{'template-button-active': activeButton === 0}" [routerLink]="'/external-statements/citizen'" [disabled]="!subscribe?.closed">{{'foreignPerson' | translate}}</button>
    <button [ngClass]="{'template-button-active': activeButton === 1}" [routerLink]="'/external-statements/admission'" [disabled]="!subscribe?.closed">{{'entrant' | translate}}</button>
  </div>
  <div class="template">
    <div class="template-head">
      <h4>
        <ng-container *ngIf="lang === 'Geo'">
          შპს "დავით ტვილდიანის სამედიცინო <br />
          უნივერსიტეტის" რექტორს, ბ-ნ <strong>ლ. ტვილდიანს</strong><br>
        </ng-container>
        <ng-container *ngIf="lang === 'Eng'">
          <strong>To:</strong> Levan Tvildiani<br>
          The Rector of David Tvildiani Medical University<br />
        </ng-container>
        <strong>{{'firstName' | translate}}: </strong><input type="text" placeholder=" " (input)="setInfo('firstName', $event.target.value)"><br>
        <strong>{{'lastName' | translate}}: </strong><input type="text" placeholder=" " (input)="setInfo('lastName', $event.target.value)"><br>
        <strong>{{'identityNumber' | translate}}: </strong><input type="text" placeholder=" " (input)="setInfo('identityNumber', $event.target.value)"><br>
        <ng-container *ngIf="activeButton === 1"><strong>{{'address' | translate}}: </strong><input type="text" placeholder=" " (input)="setInfo('address', $event.target.value)"><br></ng-container>
        <strong>{{'email' | translate}}: </strong><input type="text" placeholder=" " (input)="setInfo('email', $event.target.value)"><br>
        <ng-container *ngIf="activeButton === 1"><strong>{{'mobileNumber' | translate}}: </strong><input type="text" placeholder=" " (input)="setInfo('phone', $event.target.value)"><br></ng-container>
      </h4>
    </div>
    <div class="template-body" [ngStyle]="{'margin-top': activeButton === 1 ? '12px' : '60px'}">
      <h1 class="template-body-title">{{'chancellery.statement' | translate}}</h1>
      <div class="template-body-text">
        <textarea
          id="template-textarea"
          name="template-textarea"
          rows="5"
          [placeholder]="'chancellery.enterText' | translate"
          (input)="onTemplateTextInput($event.target.value)"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="template-footer">
    <div class="file">
      <span>{{ "attachedDocument" | translate }}:</span>
      <label
        for="attachment"
        class="mat-stroked-button mat-primary"
        >{{ "attachFile" | translate }}</label
      >
      <input
        id="attachment"
        type="file"
        name="attachment"
        class="d-none"
        (change)="handleFileInput($event)"
      />
      <ng-container *ngIf="files?.length > 0">
        <span  *ngFor="let file of files; let i = index" class="file-name" (click)="onRemoveFile(i)">
          {{ file.name }};
        </span>
      </ng-container>
    </div>
    <div *ngIf="files?.length > 0">
      {{ "chancellery.papersCount" | translate }}:
      <input
        id="papersCount"
        type="number"
        name="papersCount"
        min="0"
        placeholder="         "
        (input)="setPapersCount($event.target.value)"
      />
    </div>
    <div>
      {{ "date" | translate }}: {{ currentDate | date : "dd.MM.yyyy" }}
    </div>
  </div>
  <div class="template-action-buttons">
    <button mat-stroked-button color="base" [routerLink]="'/auth/login'">
      {{ "cancel" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="button"
      [disabled]="!subscribe?.closed"
      (click)="sendOutsideTemplate()"
    >
      {{ "send" | translate }}
      <div *ngIf="!subscribe?.closed" class="spinner-border text-light ml-3" role="status"></div>
    </button>
  </div>
</div>

<ng-template #loader>
  <div class="text-center">
    <app-loader></app-loader>
  </div>
</ng-template>