import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) { }

  getSchedulesList(params): Observable<any> {
    return this.http.get('schedule/list', {params});
  }

  delete(entityIds): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: entityIds,
    };
    return this.http.delete(`schedule`, options);
  }

  changeAudiance(body): Observable<any> {
    return this.http.post('schedule/held-in-audience', body);
  }

  updateData(body): Observable<any> {
    return this.http.put(`schedule`, body);
  }

  updateSingleData(body): Observable<any> {
    return this.http.put(`schedule/single`, body);
  }

  postData(body, hourCount): Observable<any> {
    return this.http.post(`schedule?hourCount=${hourCount}`, body);
  }

  getLecturers(): Observable<any> {
    return this.http.get('lecturers/list', {});
  }

  getGroups(): Observable<any> {
    return this.http.get('schedule/groups-list', {});
  }

  getGroupsSchedule(options): Observable<any> {
    return this.http.get('schedule/groups', options);
  }

  getAudiences(): Observable<any> {
    return this.http.get('audiences/search', {params: {page: '0', pageSize: '10000'}});
  }

  getCurations(): Observable<any> {
    return this.http.get('curations/for-list', {});
  }

  setLink(body): Observable<any> {
    return this.http.post(`schedule/add-zoomlink`, body);
  }

}
