import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './containers/auth-layout/auth-layout.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { AuthGuard } from './core/guards';
import { PreventFinancesGuard } from './core/guards/prevent-finances.guard';
import { PreventLecturerGuard } from './core/guards/prevent-lecturer.guard';
import { PreventLibrarianGuard } from './core/guards/prevent-librarian.guard';
import { PreventStudentGuard } from './core/guards/prevent-student.guard';
import { PreventTableAdminGuard } from './core/guards/prevent-tableAdmin.guard';
import { PreventTableViewGuard } from './core/guards/prevent-tableView.guard';
import { PreventZoomGuard } from './core/guards/prevent-zoom.guard';
import { ExternalStatementsComponent } from './features/auth/components/external-statements/external-statements.component';

export const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'curations',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/curations/curations.module').then(
            (m) => m.CurationsModule
          ),
      },
      {
        path: 'external-subjects',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/external-subjects/external-subjects.module').then(
            (m) => m.ExternalSubjectsModule
          ),
      },
      {
        path: 'modules',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/modules/modules.module').then(
            (m) => m.ModulesModule
          ),
      },
      {
        path: 'subjects',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventTableViewGuard, PreventFinancesGuard],
        loadChildren: () =>
          import('./features/subjects/subjects.module').then(
            (m) => m.SubjectsModule
          ),
      },
      {
        path: 'systems',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventTableViewGuard, PreventFinancesGuard],
        loadChildren: () =>
          import('./features/systems/systems.module').then(
            (m) => m.SystemsModule
          ),
      },
      {
        path: 'students',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventTableViewGuard],
        loadChildren: () =>
          import('./features/students/students.module').then(
            (m) => m.StudentsModule
          ),
      },
      {
        path: 'roles',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/roles/roles.module').then(
            (m) => m.RolesModule
          ),
      },
      {
        path: 'student-score',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/student-card/student-card.module').then(
            (m) => m.StudentCardModule
          ),
      },
      {
        path: 'finances',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/finances/finances.module').then(
            (m) => m.FinancesModule
          )
      },
      {
        path: 'grouped-lectures',
        canActivate: [AuthGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/grouped-lectures/grouped-lectures.module').then(
            (m) => m.GroupedLecturesModule
          ),
      },
      {
        path: 'lecturers',
        canActivate: [AuthGuard, PreventStudentGuard, PreventLibrarianGuard, PreventTableViewGuard],
        loadChildren: () =>
          import('./features/lecturers/lecturers.module').then(
            (m) => m.LecturersModule
          ),
      },
      {
        path: 'schedule',
        canActivate: [AuthGuard, PreventLibrarianGuard, PreventFinancesGuard],
        loadChildren: () =>
          import('./features/schedule/schedule.module').then(
            (m) => m.ScheduleModule
          ),
      },
      {
        path: 'library',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/library/library.module').then(
            (m) => m.LibraryModule
          ),
      },
      {
        path: 'personal-schedule',
        canActivate: [AuthGuard, PreventStudentGuard, PreventLibrarianGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/lect-schedule/lect-schedule.module').then(
            (m) => m.LectScheduleModule
          ),
      },
      {
        path: 'fill-groups',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/fill-groups/fill-groups.module').then((m) => m.FillGroupsModule),
      },
      {
        path: 'score-configuration',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/score-configuration/score-configuration.module').then((m) => m.ScoreConfigurationModule),
      },
      {
        path: 'lecturer-score',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventTableViewGuard, PreventFinancesGuard],
        loadChildren: () =>
          import('./features/lecturer-score/lecturer-score.module').then((m) => m.LecturerScoreModule),
      },
      {
        path: 'chancellery',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/chancellery/chancellery.module').then(m => m.ChancelleryModule)
      },

      {
        path: 'groups',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventTableViewGuard, PreventFinancesGuard],
        loadChildren: () =>
          import('./features/groups/groups.module').then((m) => m.GroupsModule),
      },
      {
        path: 'users',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'resources',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/resources/resources.module').then((m) => m.ResourcesModule),
      },
      {
        path: 'schedule-data',
        canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard],
        loadChildren: () =>
          import('./features/schedule-table/schedule-table.module').then((m) => m.ScheduleTableModule),
      },
      // {
      //   path: 'groups-view',
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import('./features/groups-view/groups-view.module').then((m) => m.GroupsViewModule),
      // },
      {
        path: 'sheets',
        canActivate: [AuthGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard],
        loadChildren: () =>
          import('./features/group-sheets/group-sheets.module').then((m) => m.GroupSheetsModule),
      },
      { path: 'feedbacks', canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard], loadChildren: () => import('./features/feedbacks/feedbacks.module').then(m => m.FeedbacksModule) },
      { path: 'audiences', canActivate: [AuthGuard, PreventLecturerGuard, PreventStudentGuard, PreventLibrarianGuard, PreventTableViewGuard, PreventFinancesGuard], loadChildren: () => import('./features/audiences/audiences.module').then(m => m.AudiencesModule) },
    ],
  },
  { path: 'group-sheets', canActivate: [AuthGuard, PreventStudentGuard, PreventLibrarianGuard, PreventZoomGuard, PreventTableViewGuard, PreventFinancesGuard, PreventTableAdminGuard], loadChildren: () => import('./features/group-sheets/group-sheets.module').then(m => m.GroupSheetsModule) },
  { path: 'home', canActivate: [AuthGuard], loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule) },
  {
    path: 'external-statements',
    children: [
      { path: '', redirectTo: '/citizen', pathMatch: 'full' },
      { path: 'citizen', component: ExternalStatementsComponent },
      { path: 'admission', component: ExternalStatementsComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
