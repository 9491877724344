import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SEMESTER } from '../../constants/dropdowns/semester';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_PAGING } from '../../constants/paging/default-paging';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Campus, StudingLanguage, Program, StudentStatus, CURRENCY } from '../../constants';

@Component({
  selector: 'app-extend-select',
  templateUrl: './extend-select.component.html',
  styleUrls: ['./extend-select.component.scss']
})
export class ExtendSelectComponent implements OnInit {
  @Input() showChips = true;
  @Input() lang: string = 'Geo';
  @Input() displayedInputs: string[];
  @Input() curationsList: any[];
  @Input() lecturers: any[];
  @Input() groups: any[];
  @Input() modules: any[];
  @Input() activeYear: string = '';
  @Input() activeYearToSet: string;
  @Output() filterValue = new EventEmitter;
  @Input() multiselect: boolean = !undefined ? true : false;
  campusOptions = [{ display: 'Rustavi', value: Campus.Rustavi }, { display: 'Tbilisi', value: Campus.Tbilisi }];


  studingLanguageOptions = [{ display: 'English', value: StudingLanguage.English },
  { display: 'Georgian', value: StudingLanguage.Georgian }];

  programOptions = [{ display: 'MD', value: Program.MD }, { display: 'PBL', value: Program.PBL }, { display: 'Biomedical Science', value: Program.BiomedicalScience }];
  studentStatusOptions = [{ display: 'Moved', value: StudentStatus.Moved }, { display: 'Active', value: StudentStatus.Active }, { display: 'Graduate', value: StudentStatus.Graduate }, { display: 'Listener', value: StudentStatus.Listener }, { display: 'Other', value: StudentStatus.Other },
  { display: 'Suspended', value: StudentStatus.Suspended }, { display: 'Terminated', value: StudentStatus.Terminated }];
  currencyOptions = [{ display: 'GEL', value: CURRENCY.GEL }, { display: 'USD', value: CURRENCY.USD }];
  academicSeasonOptions = [ { display: 'FALL', value: 1}, { display: 'SPRING', value: 0}];
  semesterEnum = SEMESTER;

  extendSelect: FormGroup;
  chips = [];
  groupsList = [];
  citizenship = [];
  pagingParams = JSON.parse(JSON.stringify(DEFAULT_PAGING));;
  // isSort = false;
  // sort = new FormControl('false')
  constructor(private fb: FormBuilder, private httpClient: HttpClient) {
    this.extendSelect = this.fb.group(
      {
        semester: [''],
        group: [''],
        campus: [''],
        studingLanguage: [''],
        module: [''],
        citizenship: [''],
        program: [''],
        curation: [''],
        lecturer: [''],
        lecturerId: [''],
        status: [''],
        groupStatus: ['active'],
        year: [''],
        sesion: [''],
        moduleQty: [0],
        gpaSemester: [''],
        sortByGPA: [''],
        isRating: [''],
        courseSemesters: [''],

        // sort : this.sort
      }
    );
  }

  ngOnInit(): void {
    this.getCitizenship();
    this.extendSelect.valueChanges.pipe(debounceTime(500),
      distinctUntilChanged()).subscribe((val: any) => {
        
        this.chips = Object.values(val); 
        this.chips = this.chips.filter(e => e != '' || null);
         this.chips = this.chips.toString().split(',');

        this.filterValue.emit(this.getPurgedObj(val));
      });
    // this.getGroups();
  }

  getPurgedObj(obj: {}) {

    const isEmpty = obj => {
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    };

    if (!isEmpty(obj)) {
      const stringfiedObj = JSON.stringify(obj, (key, value) => {
        return ['', null].includes(value) || (typeof value === 'object' && (value.length === 0 || Object.keys(value).length === 0)) ? undefined : value;
      });
      const resObj = JSON.parse(stringfiedObj);
      const isEmptyPropsPresent = ['{}', '[]', '""', 'null'].some((key) => stringfiedObj.includes(key));
      if (isEmptyPropsPresent) {
        return this.getPurgedObj(resObj);
      }
      return resObj;
    } else {
      return obj;
    }

  }

  filterData(data): void {
    this.lecturers.forEach(item => {
      if (!item.display.includes(data)) {
        item.hide = true;
      }
    });
  }

  displayCurationOptionFn = (option: any) => {
    return option;
  }

  selectLecturer(value: any): void {
    this.extendSelect.get('lecturer').patchValue(value.name);
    this.extendSelect.get('lecturerId').patchValue(value.value);
  }

  setLecturerId(id: string): void {
    this.extendSelect.get('lecturerId').patchValue(id);
  }

  getCitizenship() {
    this.httpClient.get('students/citizenships').subscribe((res: []) => {
      this.citizenship = res;
    });
  }
  // getGroups(){
  //   this.httpClient
  //     .get('groups/search', {

  //       params: {
  //         page: this.pagingParams.pageIndex.toString(),
  //         name: '',
  //         pageSize:this.pagingParams.pageSize.toString()

  //       },
  //     })

  //     .subscribe((res: any[]) => {
  //       this.groupsList = res;
  //       console.log('groups',res)
  //     });
  // }

}
