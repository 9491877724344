import { SelectionModel } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { iconPath } from '../../../../utils';
import { retry } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  customColumns = ['select', 'activator', 'link', 'attributes', 'isAvailable', 'download', 'editAttribute', 'detailsAttribute', 'deleteAttribute', 'multiselect', 'toArchiveTheSheet', 'fromArchiveTheSheet', 'rolesButtons', 'passwordChange', 'return', 'openWith'];
  selection = new SelectionModel<any>(true, []);
  icon = iconPath;
  columnId: any;
  columnTitle: any;
  tooltipIsOn: boolean = false;
  tooltipEditModeIsOn = false;

  @Input() numberize = false;
  @Input() columns = [];
  @Input() data;
  @Input() headerCells = [];
  @Input() lengnth = 100;
  @Input() pageSize = 30;
  @Input() pageSizeOptions = [10, 30, 50];
  @Input() routerLink;
  @Input() title;
  @Input() rows = [];
  @Input() colHeaders;
  @Input() paginatorEnabled = true;
  @Input() toolTipSelector = false;
  @Input() detailsView = false;
  @Input() deleteEdit = true;
  @Input() wrapIsOn = false;
  @Input() isChancellery = false;
  @Input() swapPlaces = false;
  @Input() startSwap = false;
  @Input() multiStatusChanger = false;
  @Input() startMultiStatusChange = false;
  onSwap = false;
  onMultiStatusChange = false;
  elementsToSwap = [];
  elementsToMultiStatusChange = [];
  elementsToMultiStatusChangeExternal = [];

  activeItem = null;

  @Output() openWith = new EventEmitter<any>();
  @Output() editClicked = new EventEmitter<number>();
  @Output() deleteClicked = new EventEmitter<number>();
  @Output() moduleDeleteClicked = new EventEmitter<any>();
  @Output() moduleEditeClicked = new EventEmitter<any>();
  @Output() pageChanged = new EventEmitter<PageEvent>();
  @Output() rowEntityId = new EventEmitter<string>();
  @Output() detailsClicked = new EventEmitter<string>();
  @Output() rowIdemitter = new EventEmitter<any>();
  @Output() elInfo = new EventEmitter<any>();
  @Output() newInput = new EventEmitter<number>();
  @Output() toggle = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() checkIndex = new EventEmitter<any>();
  @Output() download = new EventEmitter<any>();
  @Output() return = new EventEmitter<any>();
  @Output() btnClicked = new EventEmitter<any>();
  @Output() toArchiveEmitter = new EventEmitter<any>();
  @Output() fromArchiveEmitter = new EventEmitter<any>();
  @Output() discountEmitter = new EventEmitter<any>();
  @Output() discountRecoveryEmitter = new EventEmitter<any>();
  @Output() swapClicked = new EventEmitter<any>();
  @Output() multiStatuseChangeClicked = new EventEmitter<any>();
  @Output() multiStatuseChangeExternalClicked = new EventEmitter<any>();

  displayColumns: string[] = [];

  discountRowIdArr: number[] = [];
  discountRecoveryRowIdArr: number[] = [];
  wrongDiscountInput: boolean = false;
  wrongDiscountRecoveryInput: boolean = false;
  rowId: number;

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes)
    if (this.colHeaders) {
      this.displayColumns = this.colHeaders;
    } else {
      this.displayColumns = [...this.columns.map((column) => column.column)];
    }
    if (changes.columns) {
      this.displayColumns = [...this.columns.map((column) => column.column)];
    }
  }

  change(event: PageEvent): void {
    this.pageChanged.emit(event);
  }

  edit(el): void {
    if (this.deleteEdit) {
      if (el.id) {
        this.editClicked.next(el);
      } else {
        this.editClicked.next(el);
      }
    }
  }

  remove(el): void {
    if (this.deleteEdit) {
      this.deleteClicked.next(el);
    }
  }
  deleteModule(element: any, module: any, moduleRowIndex: number, tableRowIndex: number) {
    this.moduleDeleteClicked.emit({element, module});
    this.data[tableRowIndex].modules.splice(moduleRowIndex, 1);
  }
  editModule(element: any, module: any, moduleRowIndex: number, tableRowIndex: number) {
    if(this.tooltipEditModeIsOn) {
      this.moduleEditeClicked.emit({element, module});
      this.tooltipEditModeIsOn = false;
    } else {
      this.tooltipEditModeIsOn = true;
    }
  }

  turnTooltipOn() {
    this.tooltipIsOn = true;
  }
  
  turnTooltipOff() {
    this.tooltipIsOn = false;
  }

  rowIdemit(id: number) {
    this.rowIdemitter.emit(id);
  }
  
  details(el, event?, lecturer?) {
    if (event && event.target.width) {
      return;
    }
    if (lecturer && !this.onSwap && !this.onMultiStatusChange) {
      this.elInfo.emit({ ...el, lecturer });
      return;
    }
    if (this.toolTipSelector) {
      if (this.activeItem === el) {
        this.activeItem = null;
        return;
      }
      this.activeItem = el;
      return;
    }
    if(!this.onSwap && !this.onMultiStatusChange) {
      this.detailsClicked.next(el.entityID);
      this.elInfo.emit({ ...el });
    }
  }
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => this.selection.select(row));
  }
  getValue(row, index?): void {
    this.rowEntityId.emit(row.entityId || row);
    this.checkIndex.emit({ index, item: row });
  }
  // getAllValues(){

  // }
  addNewIsOn: boolean = false;
  addNew(index: number): void {
    if(!this.addNewIsOn) {
      this.newInput.emit(index);
      this.addNewIsOn = true;
    } else {
      return;
    }
  }
  
  toggleEdit(index: number, edit: boolean): void {
    if (this.columns[index].inputValue === '' && !this.columns[index].readOnly) { 
      if(!this.addNewIsOn) {
        this.deleteData(index); 
        return;
      } 
      // else {
      //       this.columns[this.columns.length - 1].inputValue = "Null";
      // }
    }
    this.toggle.emit({ index, edit, item: this.columns[index] });
    this.addNewIsOn = false;
  }

  toArchive(el: any): void {
    this.toArchiveEmitter.emit(el);
  }

  roleActions(el: any): void {
    this.toArchiveEmitter.emit(el);
  }

  roleUsers(el: any): void {
    this.fromArchiveEmitter.emit(el);
  }

  fromArchive(el: any): void {
    this.fromArchiveEmitter.emit(el);
  }

  toggleGroups($event: MatCheckboxChange, el, index): void {
    const checkedList = this.columns[index].checkedList;
    if ($event.checked) {
      this.columns[index].checkedList = [...checkedList, el.entityId]
        .filter((v, i, a) => a.indexOf(v) === i);
    } else {
      const element = checkedList.find((item, idx) => {
        return item === el.entityId;
      });
      const elIndex = checkedList.indexOf(element);
      if (elIndex > -1) {
        this.columns[index].checkedList.splice(elIndex, 1);
      }
    }
  }

  deleteData(colIndex: number): void {
    if (this.columns[colIndex].subGroupId) {
      this.delete.emit(this.columns[colIndex]);
    } else {
      this.columns.splice(colIndex, 1);
    }
  }

  buttonClicked(el): void {
    this.btnClicked.emit(el);
  }

  checkBox(colIndex, id): boolean {
    return this.columns[colIndex].checkedList.includes(id);
  }

  hover(columnId: any, columnTitle: any) {
    this.columnId = columnId;
    this.columnTitle = columnTitle;
  }

  updateDiscount(nameInput: HTMLInputElement, rowId: number, discountRecovery: number, financeId: string) {
    if(Number(nameInput.value) >= 0) {
    this.discountRowIdArr.splice(this.discountRowIdArr.indexOf(rowId), 1);
    this.discountEmitter.emit({nameInput: nameInput.value, rowId: rowId, discountRecovery: discountRecovery, financeId: financeId});
    this.wrongDiscountInput = false;
    } else {
      this.wrongDiscountInput = true;
    }
  }
  updateDiscountRecovery(nameInput: HTMLInputElement, rowId: number, discount: number, financeId: string) {
    if(Number(nameInput.value) >= 0) {
      this.discountRecoveryRowIdArr.splice(this.discountRecoveryRowIdArr.indexOf(rowId), 1);
      this.discountRecoveryEmitter.emit({nameInput: nameInput.value, rowId: rowId, discount: discount, financeId: financeId});
      this.wrongDiscountRecoveryInput = false;
    } else {
      this.wrongDiscountRecoveryInput = true;
    }
  }
  discountInputClicked(rowId: number) {
    if(this.discountRowIdArr.indexOf(rowId) < 0) {
      this.discountRowIdArr.push(rowId);
    }
   this.rowId = rowId;
   this.wrongDiscountInput = false;
  }
  discountRecoveryInputClicked(rowId: number) {
    if(this.discountRecoveryRowIdArr.indexOf(rowId) < 0) {
      this.discountRecoveryRowIdArr.push(rowId);
    }
    this.rowId = rowId;
    this.wrongDiscountRecoveryInput = false;
  }
  emitSwap(el) {
    if(this.elementsToSwap.indexOf(el.entityId) > -1) {
      this.elementsToSwap.splice(this.elementsToSwap.indexOf(el.entityId), 1);
    } else {
      this.elementsToSwap.push(el.entityId);
    }
    this.swapClicked.emit(this.elementsToSwap);
  }

  emitMultiStatuses(el, y) {
    if(el.entityId) {
      if(this.elementsToMultiStatusChange.indexOf(el.entityId) > -1) {
        this.elementsToMultiStatusChange.splice(this.elementsToMultiStatusChange.indexOf(el.entityId), 1);
      } else {
        this.elementsToMultiStatusChange.push(el.entityId);
      }
    } else {
      if(!el.isExternalSubject) {
        for(let i = 0; i < this.elementsToMultiStatusChange.length; i++){
          if(this.elementsToMultiStatusChange[i].rowId === y) {
            this.elementsToMultiStatusChange.splice(i, 1);

            this.multiStatuseChangeClicked.emit(this.elementsToMultiStatusChange);
            return;
          }
        }
          this.elementsToMultiStatusChange.push({
            rowId: y,
            curationId: el.curationId,
            groupId: el.groupId,
            moduleId: el.moduleId,
          });
      
      } else {
        for(let i = 0; i < this.elementsToMultiStatusChangeExternal.length; i++){
          if(this.elementsToMultiStatusChangeExternal[i].rowId === y) {
            this.elementsToMultiStatusChangeExternal.splice(i, 1);
            this.multiStatuseChangeClicked.emit(this.elementsToMultiStatusChangeExternal);
            return;
          }
        }
          this.elementsToMultiStatusChangeExternal.push({
            rowId: y,
            curationId: el.curationId,
            groupId: el.groupId,
            moduleId: el.moduleId,
          });
      }
    }
    this.multiStatuseChangeClicked.emit(this.elementsToMultiStatusChange);
    this.multiStatuseChangeExternalClicked.emit(this.elementsToMultiStatusChangeExternal);
  }
}
