import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import {TranslateService} from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal, private translateService: TranslateService) { }

  public confirm(
    title = '',
    message: string,
    newBtnCancel: string = 'cancel',
    btnOkText: string = 'yeas',
    btnCancelText: string = 'no',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean | null> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = this.translateService.instant('Dialog.' + message);
    modalRef.componentInstance.btnOkText = this.translateService.instant('Dialog.' + btnOkText);
    modalRef.componentInstance.btnCancelText = this.translateService.instant('Dialog.' + btnCancelText);
    modalRef.componentInstance.newBtnCancel = this.translateService.instant('Dialog.' + newBtnCancel);

    return modalRef.result;
  }

}
