import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { iconPath } from 'src/utils';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, AfterViewInit {
  @Input() activeItem = null;
  @Input() list = [];
  @Input() inputField = false;
  @Input() placeholder = '';
  @Input() height = '40px';
  @Input() disabled = false;
  @Input() prefix;
  @Input() reversed = false;
  @Input() scorer = false;
  @Input() altered = false;
  @Input() isMosceScore = false;
  @Input() mosceScore: any;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('listBox') listBox: HTMLElement;

  icon: string = iconPath;

  visible = false;
  element;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.element = this.listBox;
  }

  setOpener(autoFocus = true): void {
    this.visible = !this.visible;
    if (autoFocus) { this.element.nativeElement.focus(); }
  }
  setActive(item): void {
    if (item.name === this.activeItem) {
      this.valueChange.emit(null);
    }
    this.valueChange.emit(item);
    this.visible = false;
  }

  searchInput(event): void {
    if (!event.length) {
      this.valueChange.emit(null);
      this.visible = true;
    }
  }

  close(event: any): void {
    if (!(this.element.nativeElement as HTMLElement).contains(event.relatedTarget)) {
      this.visible = false;
    }
  }
}
