import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }

  set(property: string, value: any): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(property, JSON.stringify(value));
    }
  }


  get(property: string): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(property)) {
        return JSON.parse(localStorage.getItem(property));
      }
    }
  }

  remove(property: string): any {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(property);
    }
  }

}
