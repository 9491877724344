import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import {
  AsideComponent,
  FooterComponent,
  HeaderComponent,
  HeaderMobileComponent,
  ScriptsInitComponent
} from './components';
import { SharedModule } from '../../shared.module';
import {MatTreeModule} from '@angular/material/tree';

@NgModule({
  declarations: [
    AsideComponent,
    FooterComponent,
    AsideComponent,
    HeaderComponent,
    HeaderMobileComponent,
    ScriptsInitComponent,
  ],
    imports: [
        CommonModule,
        InlineSVGModule,
        RouterModule,
        TranslateModule,
        SharedModule,
        MatTreeModule
    ],
  exports: [
    AsideComponent,
    FooterComponent,
    AsideComponent,
    HeaderComponent,
    HeaderMobileComponent,
    ScriptsInitComponent,
  ]
})
export class MetronicModule { }
