<app-header-mobile (toggleMenu)="appendPopup()" #ktHeaderMobile id="kt_header_mobile" class="no-print header-mobile align-items-center"
  [ngClass]="headerMobileClasses"></app-header-mobile>
<div class="d-flex flex-column flex-root">
  <div class="d-flex flex-row flex-column-fluid page">
    <ng-container *ngIf="asideSelfDisplay">
      <app-aside #ktAside id="kt_aside"
        class="aside aside-left d-flex flex-column flex-row-auto justify-content-between" [ngClass]="asideCSSClasses">
      </app-aside>
    </ng-container>

    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <div class="container no-print">
        <div class="float-left backBtn-container" (click)="feedBackBox = !feedBackBox; startTimer()">
          <button mat-icon-button>
            <svg class="back-icon" [ngClass]="{'closer': feedBackBox}">
              <use [attr.xlink:href]="icon + 'go-back.svg#Layer_1'"></use>
            </svg>
          </button>
        </div>
        <div class="dropdown feedback-box" [ngClass]="{'fadeIn': feedBackBox}">
          <span class="icon" (click)="feedBackBox = false">
            <img style="width: 30px; height: 30px" [src]="icon + 'close-button.svg'" alt="close">
          </span>
          <h1>მოგესალმებით!</h1>
          <!-- <p>
            ამ ეტაპისთვის ვებ-აპლიკაცია მუშაობს სატესტო რეჟიმში.
          </p> -->
          <p>
            თქვენი უკუკავშირი ჩვენთვის მნიშვნელოვანია. გთხოვთ, ხარვეზების დაფიქსირების შემთხვევაში მოგვწერეთ!
          </p>
          <div class="feedback-footer">
            <button mat-button (click)="sendFeedback(); feedBackBox = false">
              ხარვეზის დაფიქირება
            </button>
            <button mat-button (click)="feedBackBox = false">
              კარგი
            </button>
          </div>
        </div>
        <div *ngIf="admin" class="float-right avatar-container" style="margin-top: 3px; margin-left: 20px;">
          <button mat-icon-button (click)="showInput()">
            <svg width="24px" id="Layer_1" height="24px" viewBox="0 0 24 24" version="1.1">
              <defs></defs>
              <g id="Stockholm-icons-/-General-/-User" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#ffffff" fill-rule="nonzero" stroke="#27426C"></path>
                <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#ffffff" fill-rule="nonzero" stroke="#27426C"></path>
              </g>
            </svg>
          </button>
        </div>
        <div class="dropdown" tabindex="-1" (blur)="showDropDown = false" *ngIf="showDropDown">
          <ul>
            <li (click)="appendPopup()">{{'password_change' | translate}}</li>
          </ul>
        </div>
        <div class="float-right language-container">
            <app-change-language></app-change-language>
        </div>
      </div>

      <div class="content d-flex flex-column flex-column-fluid" id="kt_content" [ngClass]="contentClasses">

        <ng-container *ngIf="contentExtended">
          <router-outlet></router-outlet>
        </ng-container>

        <ng-container *ngIf="!contentExtended">

          <div class="d-flex flex-column-fluid">
            <div [ngClass]="contentContainerClasses">
              <router-outlet></router-outlet>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</div>

<app-scripts-init></app-scripts-init>
