<svg xmlns="http://www.w3.org/2000/svg" width="41" height="36" viewBox="0 0 41 36">
  <g id="Group_52" data-name="Group 52" transform="translate(-356 -522)">
    <g id="Rectangle_33" data-name="Rectangle 33" transform="translate(356 522)" fill="#fff" stroke="#707070" stroke-width="0.4">
      <rect width="41" height="36" rx="7" stroke="none"/>
      <rect x="0.2" y="0.2" width="40.6" height="35.6" rx="6.8" fill="none"/>
    </g>
    <g id="Group_41" data-name="Group 41" transform="translate(10.466 -1.195)">
      <g id="Rectangle_30" data-name="Rectangle 30" transform="translate(369.406 531.563) rotate(34)" fill="#868686" stroke="#868686" stroke-width="1">
        <rect width="5.677" height="19.303" stroke="none"/>
        <rect x="0.5" y="0.5" width="4.677" height="18.303" fill="none"/>
      </g>
      <g id="Polygon_1" data-name="Polygon 1" transform="translate(360.144 555.447) rotate(-146)" fill="#868686">
        <path d="M 4.775119304656982 4.04179859161377 L 0.9021274447441101 4.04179859161377 L 2.838623285293579 0.9433997869491577 L 4.775119304656982 4.04179859161377 Z" stroke="none"/>
        <path d="M 2.838623285293579 1.88680100440979 L 1.804251670837402 3.54179859161377 L 3.872994899749756 3.54179859161377 L 2.838623285293579 1.88680100440979 M 2.838623285293579 -1.430511474609375e-06 L 5.677243232727051 4.54179859161377 L 3.337860107421875e-06 4.54179859161377 L 2.838623285293579 -1.430511474609375e-06 Z" stroke="none" fill="#868686"/>
      </g>
      <g id="Rectangle_31" data-name="Rectangle 31" transform="translate(371.311 528.739) rotate(34)" fill="#868686" stroke="#868686" stroke-width="1">
        <rect width="5.677" height="2.271" stroke="none"/>
        <rect x="0.5" y="0.5" width="4.677" height="1.271" fill="none"/>
      </g>
    </g>
  </g>
</svg>
