import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {dateToString} from '../../../utils';

@Component({
  selector: 'app-salaries-export',
  templateUrl: './salaries-export.component.html',
  styleUrls: ['./salaries-export.component.scss']
})
export class SalariesExportComponent implements OnInit {
  presureForm: FormGroup = new FormGroup({
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required)
  });
  loader = false;

  choosenPeriod: any;
  fromDate: string;
  toDate: string;

  @ViewChild('periodInput') periodInput: ElementRef;
  inputEmpty: boolean = true;

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {}

  setMonthAndYear(normalizedMonthAndYear: any, datepicker: any) {
    const firstDay = normalizedMonthAndYear;
    const date = normalizedMonthAndYear.toString();
    const year = date.split(" ")[3];
    let month = date.split(" ")[1];
    switch(month) {
      case 'Jan':
        month = 0;
        break;
      case 'Feb':
        month = 1;
        break;
      case 'Mar':
        month = 2;
        break;
      case 'Apr':
        month = 3;
        break;
      case 'May':
        month = 4;
        break;
      case 'Jun':
        month = 5;
        break;
      case 'Jul':
        month = 6;
        break;
      case 'Aug':
        month = 7;
        break;
      case 'Sep':
        month = 8;
        break;
      case 'Oct':
        month = 9;
        break;
      case 'Nov':
        month = 10;
        break;
      case 'Dec':
        month = 11;
        break;
    }
    const lastDay = new Date(year, month + 1, 0);
    this.fromDate = dateToString(firstDay);
    this.toDate = dateToString(lastDay);
    this.periodInput.nativeElement.value = this.fromDate + ' to ' + this.toDate;
    this.inputEmpty = false;
    datepicker.close();
  }
  
  exportData(): void {
    this.loader = true;
    // this.httpClient.get(`lecturers/export_hours_accounting?fromDate=${dateToString(this.presureForm.value.fromDate)}&toDate=${dateToString(this.presureForm.value.toDate)}`, { ...{ responseType: 'arraybuffer', observe: 'response' } }).subscribe((res: any) => {
    this.httpClient.get(`lecturers/export_hours_accounting?fromDate=${this.fromDate}&toDate=${this.toDate}`, { ...{ responseType: 'arraybuffer', observe: 'response' } }).subscribe((res: any) => {
      this.loader = false;
      const type = res.headers.get('Content-Type');
      // download file with name
      const blob = new Blob([res.body], { type: 'xlsx' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'salaries.xlsx';
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }, error => {
      this.loader = false;
    }
    );
  }

}
