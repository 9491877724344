<mat-card>
  <mat-card-content>
    <div class="table-responsive">
      <mat-table #table [dataSource]="data">
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <!-- <mat-checkbox (change)="getAllValues()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox> -->
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let row">
            <mat-checkbox (change)="getValue(row)" (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="detailsAttribute">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="icons selector" style="overflow: inherit" *matCellDef="let element; let colIndex = index">
            <span #tooltip="matTooltip" matTooltip="{{ 'Details' | translate }}" (click)="details(element)"
              class="material-icons">
              <app-more-vert class="cursor-pointer"></app-more-vert>
            </span>
            <div class="toolbar" *ngIf="toolTipSelector && activeItem === data[colIndex]">
              <div *ngFor="let lecturer of element.lecturers" class="selected"
                (click)="details(element, colIndex, lecturer)">{{lecturer.lecturerFirstNameGeo}}</div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rolesButtons">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="roles" *matCellDef="let element">
            <div style="display: flex; gap: 35px">
              <button mat-button class="roleButton" (click)="roleActions(element)">
                <span>
                  {{'addActions' | translate}}
                  <app-add-icon></app-add-icon>
                </span>
              </button>
              <button mat-button class="roleButton" (click)="roleUsers(element)">
                <span>
                  {{'addUsers' | translate}}
                  <app-add-icon></app-add-icon>
                </span>
              </button>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="attributes">
          <mat-header-cell *matHeaderCellDef>{{(deleteEdit ? 'DeleteEdit' : '') | translate}}</mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <input *ngIf="multiStatusChanger" (mouseenter)="onMultiStatusChange = true" (mouseleave)="onMultiStatusChange = false" (input)="emitMultiStatuses(element, y)" type="checkbox" class="custom-checkbox" [ngClass]="{'custom-checkbox-visible': startMultiStatusChange === true}">
            <app-trash-icon (click)="remove(element)" class="mx-3 cursor-pointer" *ngIf="deleteEdit"></app-trash-icon>
            <app-edit-icon (click)="edit(element)" class="cursor-pointer" *ngIf="deleteEdit"></app-edit-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="toArchiveTheSheet">
          <mat-header-cell *matHeaderCellDef>{{
            "toArchive" | translate
            }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button class="archiveIcon" (click)="toArchive(element)">
              <img [src]="icon + 'toArchive.svg#Layer_1'" alt="fromArchive" />
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fromArchiveTheSheet">
          <mat-header-cell *matHeaderCellDef style="white-space: normal">{{
            "fromArchive" | translate
            }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button class="archiveIcon" (click)="fromArchive(element)">
              <img [src]="icon + 'fromArchive.svg#Layer_1'" alt="fromArchive" />
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="editAttribute">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <app-edit-icon (click)="edit(element)" class="cursor-pointer"></app-edit-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="download">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <button mat-raised-button *ngIf="element.isElectronic" (click)="download.emit(element)">{{'download' |
              translate}}</button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isAvailable">
          <mat-header-cell *matHeaderCellDef>
            {{"isAvailable" | translate}}
          </mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <div class="circle red" *ngIf="!element.isAvailable"></div>
            <div class="circle" *ngIf="element.isAvailable"></div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="return">
          <mat-header-cell *matHeaderCellDef>{{'status' | translate}}</mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <ng-container *ngIf="!element.returnDate; else status">
              <button mat-raised-button (click)="return.emit(element)">{{'return' | translate}}</button>
            </ng-container>
            <ng-template #status>
              <span>{{'returned' | translate}}</span>
            </ng-template>
          </mat-cell>
        </ng-container>
        <!--        <ng-container matColumnDef="return">-->
        <!--          <mat-header-cell *matHeaderCellDef></mat-header-cell>-->
        <!--          <mat-cell class="icons" *matCellDef="let element">-->
        <!--            <button class="btn" mat-raised-button [disabled]="element.disabled">დაბრუნება</button>-->
        <!--          </mat-cell>-->
        <!--        </ng-container>-->
        <ng-container matColumnDef="openWith">
          <mat-header-cell *matHeaderCellDef>{{'new_window' | translate}}</mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <img (click)="openWith.emit(element)" [src]="icon + 'external.png'" alt="external" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="activator">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <img style="max-width: 30px; max-height: 30px; cursor: pointer" (click)="openWith.emit(element)"
              [src]="icon + 'disable.png'" alt="disable" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="link">
          <mat-header-cell *matHeaderCellDef>{{'address' | translate}}</mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <a style="color: blue" [href]="'' + element.link" target="_blank">{{element.link.length > 70 ?
              (element.link.substring(0, 70) + '...') : element.link}}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="passwordChange">
          <mat-header-cell *matHeaderCellDef>{{'password_change' | translate}}</mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <button class="archiveIcon" mat-button (click)="fromArchive(element)">
              <img [src]="icon + 'change-password.svg#Layer_1'" alt="change_password" />
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deleteAttribute">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell class="icons" *matCellDef="let element">
            <app-trash-icon (click)="remove(element)" class="mx-3 cursor-pointer"></app-trash-icon>
          </mat-cell>
        </ng-container>
        <ng-container *ngFor="let column of columns; let colIndex = index">
          <ng-container *ngIf="!customColumns.includes(column.column)" [matColumnDef]="column.column">
            <div *ngIf="column.title === 'add-new'; else cols">
              <mat-header-cell *matHeaderCellDef>
                <app-add-icon class="cursor-pointer" (click)="addNew(colIndex)"></app-add-icon>
              </mat-header-cell>
            </div>
            <div *ngIf="column.title === 'multiselect'; else cols">
              <mat-header-cell *matHeaderCellDef>
                <div class="check-header">
                  <input type="text" placeholder="ჯგუფი" *ngIf="!column.readOnly; else readOnly"
                    [(ngModel)]="column.inputValue" />
                  <svg (click)="toggleEdit(colIndex, false)" *ngIf="!column.readOnly">
                    <use [attr.xlink:href]="icon + 'done.svg#Layer_1'"></use>
                  </svg>
                  <ng-template #readOnly>
                    <span>{{ column.inputValue }}</span>
                    <app-edit-icon class="cursor-pointer edit-icon" *ngIf="column.readOnly"
                      (click)="toggleEdit(colIndex, true)"></app-edit-icon>
                    <app-trash-icon class="cursor-pointer edit-icon" *ngIf="column.readOnly"
                      (click)="deleteData(colIndex)"></app-trash-icon>
                  </ng-template>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-checkbox (change)="toggleGroups($event, element, colIndex)"
                  [checked]="checkBox(colIndex, element.entityId)" [disabled]="column.readOnly">
                </mat-checkbox>
              </mat-cell>
            </div>
            <ng-template #cols>
              <mat-header-cell *matHeaderCellDef style="white-space: normal; text-align: center;"
              [ngStyle]="{'width': wrapIsOn && (column.column === 'debt' || column.column === 'payableGEL' || column.column === 'payableUSD') ? '160px' : 'unset'}">{{
                column.title | translate
                }}</mat-header-cell>
              <mat-cell *matCellDef="let element; let y = index">
                <span style="display: block;"
                  [ngStyle]="{'white-space': (column.column === 'nameGeo' || column.column === 'nameEng' || column.column === 'lecturerNameEng' || column.column === 'lecturerNameGeo' || column.column === 'curationNameGeo' || column.column === 'curationNameEng' || column.column === 'name' || column.column === 'groupNames') && wrapIsOn ? 'normal' : 'unset', 'width': wrapIsOn && (column.column === 'nameGeo' || column.column === 'nameEng' || column.column === 'curationNameGeo' || column.column === 'curationNameEng') ? '310px' : (column.column === 'lecturerNameEng' || column.column === 'lecturerNameGeo' || column.column === 'name') && wrapIsOn ? '230px' : 'unset', 'text-align': (column.column === 'moduleNameGeo' || column.column === 'moduleNameEng') ? 'left' : (column.column === 'debt' || column.column === 'createDate') ? 'center' : 'unset', 'padding-left': (column.column === 'moduleNameGeo' || column.column === 'moduleNameEng') ? '30px' : 'unset'}"
                  [ngClass]="{'red-color': element[column.column] === 'Suspended' || element[column.column] === 'Terminated' || element[column.column] === 'Moved', 'disabled': (element?.isActive === false), 'text-center': column.title === 'titleOrContent' || column.title === 'documentNumber' || column.title === 'campus' || column.title === 'lecturer' || column.title === 'senderFirstName'}">
                  <ng-container
                    *ngIf="element[column.column] && column.column === 'lecturerNameGeo' && element[column.column].length > 30; else shorten">
                    <span [title]="element[column.column]">
                      <!-- {{element[column.column].toString().substring(0, 30) + '...'}} -->
                      {{element[column.column]}}
                    </span>
                  </ng-container>
                  <ng-template #shorten>

                    <ng-container *ngIf="column.column === 'payableUSD' || column.column === 'payableGEL'; else showDefault">
                      <span
                        [ngClass]="{'red-color': +element[column.column].split(' ')[0] > 0, 'green-color': +element[column.column].split(' ')[0] < 0 }">
                        {{element[column.column] | translate}}
                      </span>
                    </ng-container>
                    <span *ngIf="column.column === 'total'"> {{ element.lastPayDate | date }} </span>

                    <ng-template #showDefault>
                      <span *ngIf="column.column !== 'discount' && column.column !== 'discountRecovery' && column.column !== 'endingStatusComment'" (mouseenter)="hover(y, column.title); turnTooltipOn()" (mouseleave)="turnTooltipOff()"
                        [ngClass]="{'red-color': (element[column.column] > 0 && column.title === 'debt') || (column.column === 'status' && element.isOverdue), 'green-color': (column.column === 'quiz0' && element.quiz0IsFailed === true) || (column.column === 'quiz1' && element.quiz1IsFailed === true) || (column.column === 'quiz2' && element.quiz2IsFailed === true) || (column.column === 'quiz3' && element.quiz3IsFailed === true) || (column.column === 'quiz4' && element.quiz4IsFailed === true) || (column.column === 'quiz5' && element.quiz5IsFailed === true) || (element[column.column] < 0 && column.title === 'debt') || (column.column === 'status' && element.status === 'finished'), 'orange-color': (column.column === 'status' && element.status === 'current'), 'purple-color': column.column === 'status' && element.status === 'unfulfilled'}">
                        <input *ngIf="swapPlaces &&  column.title === 'rowIndex'" (mouseenter)="onSwap = true" (mouseleave)="onSwap = false" (input)="emitSwap(element)" type="checkbox" class="custom-checkbox" [ngClass]="{'custom-checkbox-visible': startSwap === true, 'accent-red': elementsToSwap.length > 2}">
                        <input *ngIf="multiStatusChanger && (column.title === 'rowIndex' || column.title === 'code')" (mouseenter)="onMultiStatusChange = true" (mouseleave)="onMultiStatusChange = false" (input)="emitMultiStatuses(element, y)" type="checkbox" class="custom-checkbox" [ngClass]="{'custom-checkbox-visible': startMultiStatusChange === true}">
                        {{ element[column.column] | translate}}
                        <ng-container *ngIf="tooltipIsOn && columnId === y && columnTitle === 'payable' && column.title === 'payable'">
                          <div style="position: absolute; background-color: rgb(80, 79, 79); padding: 5px 0px 5px 10px; border-radius: 5px; color: white; margin-left: 15px; margin-top: -3px;" [ngStyle]="{'background-color': element.modules.length === 0 ? 'transparent' : '#504f4f'}">
                            <div *ngFor="let module of element.modules; let l = index" style="display: flex; align-items: center; justify-content: space-between">
                              {{l + 1}}. <input class="payable" type="number" [(ngModel)]="module.payable" [disabled]="!tooltipEditModeIsOn"> - {{module.payDate.split('T')[0]}}
                              <div *ngIf="tooltipEditModeIsOn" style="transform: scale(0.7); background-color: white; padding: 6px 9px; border-radius: 8px; border: 1px solid gray" class="ml-3 cursor-pointer" (click)="editModule(element, module, l, y)">
                                <svg style="width: 20px; height: 20px" fill="#0a376fe5">
                                  <use [attr.xlink:href]="icon + 'done.svg#Layer_1'"></use>
                                </svg>
                              </div>
                              <div *ngIf="!tooltipEditModeIsOn" style="transform: scale(0.7);"><app-edit-icon (click)="editModule(element, module, l, y)" class="ml-3 cursor-pointer"></app-edit-icon></div>
                              <div style="transform: scale(0.7);"><app-trash-icon (click)="deleteModule(element, module, l, y)" class="mr-3 cursor-pointer" ></app-trash-icon></div>
                            </div>
                          </div>
                          </ng-container>
                        <ng-container *ngIf="tooltipIsOn && columnId === y && columnTitle === 'paid' && column.title === 'paid'">
                          <div style="position: absolute; background-color: rgb(80, 79, 79); padding: 8px 10px 0px; border-radius: 5px; color: white; margin-left: 25px; margin-top: -5px;" [ngStyle]="{'background-color': element.paymentHistory.length === 0 ? 'transparent' : '#504f4f'}">
                            <div *ngFor="let payment of element.paymentHistory; let l = index" style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 8px;">
                              {{l + 1}}. {{payment.paidAmount}} - {{payment.payDate.split('T')[0]}}
                            </div>
                          </div>
                          </ng-container>
                        <ng-container *ngIf="tooltipIsOn && columnId === y && columnTitle === 'GPA' && column.title === 'GPA'">
                          <div style="position: absolute; background-color: rgb(80, 79, 79); padding: 8px 10px 0px; border-radius: 5px; color: white; margin-left: 25px; margin-top: -5px;">
                            <div style="margin-bottom: 8px;">
                              10 - {{element.gpA10}}
                            </div>
                            <div style="margin-bottom: 8px;">
                              5 - {{element.gpA5}}
                            </div>
                            <div style="margin-bottom: 8px;">
                              4 - {{element.gpA4}}
                            </div>
                          </div>
                          </ng-container>
                          <ng-container *ngFor="let customQuiz of [1,2,3,4,5,6]; let q = index">
                              <ng-container *ngIf="tooltipIsOn && columnId === y && (columnTitle === 'quizN' + (q + 1)) && (column.title === 'quizN' + (q + 1))">
                                <div style="position: absolute; background-color: rgb(80, 79, 79); padding: 8px 10px 0px; border-radius: 5px; color: white; margin-left: 25px; margin-top: -5px;">
                                  <div style="margin-bottom: 8px; text-align: left;">
                                    {{'semester' | translate}} - {{element.modules[q].semester}}
                                  </div>
                                  <div style="margin-bottom: 8px; text-align: left; max-width: 250px; text-wrap: wrap;">
                                    {{'name' | translate}} - {{element['quiz' + q + 'Name']}}
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                      </span>
                      <span *ngIf="column.column === 'discount'" style="display: flex; gap: 2px; justify-content: center; align-items: center; margin-left: 15px"><input type="number" min="0" [value]="element[column.column]" style="width: 60px; border-radius: 4px;" [ngStyle]="{border: wrongDiscountInput && y === rowId ? '1px solid red' : '1px solid gray'}" (click)="discountInputClicked(y)" #discountInput>
                        <svg *ngIf="(discountRowIdArr.indexOf(y) > -1)" style="width: 20px; height: 20px; cursor: pointer" fill="#0a376fe5" (click)="updateDiscount(discountInput, y, element.discountRecovery, element.entityId)">
                        <use [attr.xlink:href]="icon + 'done.svg#Layer_1'"></use>
                      </svg>
                    </span>
                      <span *ngIf="column.column === 'discountRecovery'" style="display: flex; gap: 2px; justify-content: center; align-items: center"><input type="number" min="0" [value]="element[column.column]" style="width: 60px; border-radius: 4px;" [ngStyle]="{border: wrongDiscountRecoveryInput && y === rowId ? '1px solid red' : '1px solid gray'}" (click)="discountRecoveryInputClicked(y)" #discountRecoveryInput>
                        <svg *ngIf="(discountRecoveryRowIdArr.indexOf(y) > -1)" style="width: 20px; height: 20px; cursor: pointer" fill="#0a376fe5" (click)="updateDiscountRecovery(discountRecoveryInput, y, element.discount, element.entityId)">
                        <use [attr.xlink:href]="icon + 'done.svg#Layer_1'"></use>
                      </svg>
                    </span>
                    <span *ngIf="column.column === 'endingStatusComment'" [matTooltip]="element[column.column]" matTooltipPosition="left">
                      {{ element[column.column]?.slice(0, 15)}}
                    </span>
                    <span *ngIf="column.column === 'DS'">
                      <span *ngFor="let ds of element.curations; let dsIndex = index" style="text-wrap: wrap; position: relative;" [ngClass]="{'red-color': ds.isMissed}"
                      [matTooltip]="('students.activity' | translate) + ' - ' + ds.activity + '\n' + ('oral' | translate) + ' - ' + ds.oral + '\n' + ('quiz' | translate) + ' - ' + ds.quiz" matTooltipPosition="left">
                        {{ ds.code }}
                        <ng-container *ngIf="dsIndex !== element.curations.length - 1">
                          ,&nbsp;
                        </ng-container>
                      </span>
                    </span>
                      <!-- ***old tooltip*** -->
                      <!-- <span
                      [matTooltip]="column.title === 'payable' ? ((element.module1Payable ? element.module1Payable : 'N / A') + ' - ' + (element.module1PayDate ? element.module1PayDate.split('T')[0].replaceAll('-', ' / ') : 'N / A') + '\n' + 
                      (element.module2Payable ? element.module2Payable : 'N / A') + ' - ' + (element.module2PayDate ? element.module1PayDate.split('T')[0].replaceAll('-', ' / ') : 'N / A') + '\n' + 
                      (element.module33Payable ? element.module3Payable : 'N / A') + ' - ' + (element.module3PayDate ? element.module1PayDate.split('T')[0].replaceAll('-', ' / ') : 'N / A')) : ''"
                        aria-label="Button that displays a tooltip when focused or hovered over"
                      [ngClass]="{'red-color': element[column.column] > 0 && column.title === 'debt', 'green-color': element[column.column] < 0 && column.title === 'debt'}">
                        {{ element[column.column] | translate}}
                      </span> -->
                    </ng-template>


                  </ng-template>
                </span>
              </mat-cell>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-content></ng-content>
        <mat-header-row class="text-center" *matHeaderRowDef="displayColumns"></mat-header-row>
        <mat-row class="text-center row-selector" [ngClass]="{'row-selector': detailsView, 'opened': isChancellery && row.isOpened === true, 'unopened': isChancellery && row.isOpened === false}"
          *matRowDef="let row; columns: displayColumns; let rowId = index" (click)="rowIdemit(rowId); details(row, $event);"></mat-row>
      </mat-table>
    </div>
    <mat-paginator #paginator *ngIf="paginatorEnabled" [length]="lengnth" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="change($event)">
    </mat-paginator>
  </mat-card-content>
</mat-card>