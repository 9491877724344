import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  languages: string[] = ['ka-GE', 'en-US'];
  onLangChange: Subscription = undefined;
  timerSubscription: Subscription;

  constructor(public el: ElementRef, private translateService: TranslateService, private authService: AuthService, private bnIdle: BnNgIdleService, private matDialog: MatDialog) {

    this.translateService.addLangs(this.languages);
    this.translateService.setDefaultLang(environment.defaultLanguage);
    const appLanguage = JSON.parse(localStorage.getItem('lang'));

    if (appLanguage) {
      translateService.use(appLanguage);
    } else {
      translateService.use(environment.defaultLanguage);
    }
  }
  ngOnInit(): void {
    this.updateLanguage();
    this.onLangChange = this.translateService.onLangChange.subscribe(() => {
      this.updateLanguage();
    });
    if(JSON.parse(localStorage.getItem('userRoles')).includes('Admin')) {
      this.timerSubscription = this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
         if (isTimedOut) {
          this.authService.logout();
          this.matDialog.closeAll();
         }
       });
     }
  }

  ngOnDestroy(): void {
    if (this.onLangChange !== undefined) {
      this.onLangChange.unsubscribe();
    }
    this.timerSubscription?.unsubscribe();
  }


  updateLanguage(): void {
    const lang = document.createAttribute('lang');
    lang.value = this.translateService.currentLang;
    this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(lang);
  }

}
