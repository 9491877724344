import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/core/services';
import { Language } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent implements OnInit {
  selectedToggleFlag = false;
  currentLanguageValue: string;
  languages: Language[] = [
    { value: 'ka-GE', title: 'GE' },
    { value: 'en-US', title: 'EN' },
  ];

  constructor(
    private translateService: TranslateService,
    private localstorageService: LocalStorageService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.currentLanguageValue = this.translateService.currentLang;
  }

  onLanguageChange(language: string): void {
    this.selectedToggleFlag = true;
    this.localstorageService.set('lang', language);
    this.translateService.use(language);
    const prev = this.router.url;
    this.router.navigate(['/']).then(data => {
      this.router.navigate([prev]);
    });

  }

}
