import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';

@Component({
  selector: 'app-chancellery-dynamic-lookup',
  templateUrl: './chancellery-dynamic-lookup.component.html',
  styleUrls: ['./chancellery-dynamic-lookup.component.scss']
})
export class ChancelleryDynamicLookupComponent implements OnInit {
  @Input() items: {vale: any, name: any}[];
  @Input() activeItem: any;
  @Input() searchBox = false;
  @Input() type = 'string';
  @Input() width;
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() placeholder = 'statusFinisher';

  saveList: {vale: any, name: any}[];

  @Output() setValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeInput: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.placeholder = this.placeholder + ' *';
    this.saveList = this.items;
    if (!this.activeItem) { this.activeItem = ''; }
  }

  doFilter(): void {
    this.items = this.filter(this.items);
    if (this.items.length < 1) {
      this.items = this.saveList;
    }
  }

  filter(values): any {
    return values.filter(item => item.name.toLowerCase().includes(this.activeItem));
  }
}
