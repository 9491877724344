import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChancelleryService {
  constructor(private http: HttpClient) {}

  createDocument(payload: any): Observable<any> {
    const formData = new FormData();
    // formData.append('DocumentNumber', payload.DocumentNumber);
    formData.append('Title', payload.Title);
    formData.append('Description', payload.Description);
    if (payload.Files?.length > 0) {
      payload.Files.map((file) => {
        formData.append('Files', file);
      });
    }
    return this.http.post('document', formData);
  }

  createAdminDocument(payload: any): Observable<any> {
    const formData = new FormData();
    formData.append('Title', payload.Title);
    formData.append('Description', payload.Description);
    formData.append('FirstName', payload.FirstName);
    formData.append('LastName', payload.LastName);
    formData.append('Email', payload.Email);
    formData.append('Role', payload.Role);
    if (payload.Files?.length > 0) {
      payload.Files.map((file) => {
        formData.append('Files', file);
      });
    }
    return this.http.post('document/outside_document', formData);
  }

  createAdminOutGoingDocument(payload: any, editing: Boolean): Observable<any> {
    const formData = new FormData();
    formData.append('ShortDescription', payload.ShortDescription);
    formData.append('Note', payload.Note);
    formData.append('AddresseeFirstName', payload.AddresseeFirstName);
    formData.append('AddresseeLastName', payload.AddresseeLastName);
    // formData.append('SenderFirstName', payload.SenderFirstName);
    // formData.append('SenderLastName', payload.SenderLastName);
    formData.append(
      'AuthorizedPersonFirstName',
      payload.AuthorizedPersonFirstName
    );
    formData.append(
      'AuthorizedPersonLastName',
      payload.AuthorizedPersonLastName
    );
    // formData.append('StatusFinisherFirstName', payload.StatusFinisherFirstName);
    // formData.append('StatusFinisherLastName', payload.StatusFinisherLastName);
    formData.append('PapersCount', payload.PapersCount);
    formData.append('Status', payload.Status);
    if (payload.Files?.length > 0) {
      payload.Files.map((file) => {
        formData.append('Files', file);
      });
    }
    if (editing) {
      formData.append('EntityId', payload.EntityId);
      formData.append('EndingStatusComment', payload.EndingStatusComment);
      formData.append('ChancelleryPersonNote', payload.ChancelleryPersonNote);
      return this.http.put('outgoingdocument', formData);
    } else {
      return this.http.post('outgoingdocument', formData);
    }
  }

  deleteAdminOutgoingDocument(entityId): Observable<any> {
      return this.http.delete(`outgoingdocument/${entityId}`);
  }
  deleteAdminOutgoingFile(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body,
    };
      return this.http.delete('outgoingdocument/file', options);
  }

  getChancelleryDocuments(filters): Observable<any> {
    return this.http.get('document', { params: filters });
  }
  getChancelleryOutgoingDocuments(filters): Observable<any> {
    return this.http.get('outgoingdocument/getall', { params: filters });
  }
  getToBeConfirmed(filters): Observable<any> {
    return this.http.get('document/unaccepteddocuments', { params: filters });
  }
  getToBeConfirmedOutgoing(filters): Observable<any> {
    return this.http.get('outgoingdocument/unaccepteddocuments', { params: filters });
  }
  getStatusFinisherDocs(filters): Observable<any> {
    return this.http.get('outgoingdocument/documents_for_statusfinisher', { params: filters });
  }
  usersForStatusFinisher(filters): Observable<any> {
    return this.http.get('outgoingdocument/users_for_statusfinisher', { params: filters });
  }

  getDocumentDetails(documentId): Observable<any> {
    return this.http.get('document/details', { params: { documentId } });
  }

  getOutgoingDocumentDetails(documentId): Observable<any> {
    return this.http.get('outgoingdocument/details', {params: { documentId },
    });
  }

  addForwarding(payload): Observable<any> {
    const formData = new FormData();
    formData.append('DocumentId', payload.DocumentId);
    // formData.append('RecipientEmail', payload.RecipientEmail);
    formData.append('Description', payload.Description);
    // formData.append('Files', payload.Files);
    // formData.append('PapersCount', payload.PapersCount);
    formData.append('ExpectedEndDate', payload.ExpectedEndDate);
    if (payload.RecipientEmail?.length > 0) {
      payload.RecipientEmail.map((recipient) => {
        formData.append('RecipientEmail', recipient);
      });
    }
    if (payload.Files?.length > 0) {
      payload.Files.map((file) => {
        formData.append('Files', file);
      });
    }

    return this.http.post('document/add_forwarding', formData);
  }

  getDocumentForUser(filters): Observable<any> {
    return this.http.get('document/search_documents_foruser', {
      params: filters,
    });
  }
  getForwardedDocumentForUser(filters): Observable<any> {
    return this.http.get('document/forwarded_document_for_recipient', {
      params: filters,
    });
  }

  changeDocumentStatus(payload): Observable<any> {
    const formData = new FormData();
    formData.append('DocumentId', payload.documentId);
    formData.append('Status', payload.status);
    formData.append('Comment', payload.comment);
    formData.append('UpdateStatusDate', payload.updateStatusDate);
    if (payload.files?.length > 0) {
      payload.files.map((file) => {
        formData.append('Files', file);
      });
    }
    return this.http.put('document/update_status', formData);
  }

  addComment(payload): Observable<any> {
    return this.http.post('document/add_comment', payload);
  }


  getUnopened(): Observable<any> {
    return this.http.get('document/unopeneddocuments', {});
  }
  getForwardedUnopened(): Observable<any> {
    return this.http.get('document/unopenedforwardeddocumentsforaddresee', {});
  }

  changeOutgoingCorrespondenceStatus(payload): Observable<any> {
    return this.http.put('outgoingdocument/update_status_by_status_finisher', payload);
  }
  confirmDocument(payload): Observable<any> {
    return this.http.post('document/accept_document', payload);
  }
  confirmDocumentOutgoing(payload): Observable<any> {
    return this.http.post('outgoingdocument/accept_document', payload);
  }
  swapDocuments(payload): Observable<any> {
    return this.http.post('document/exchange_documents', payload);
  }
  swapOutgoingDocuments(payload): Observable<any> {
    return this.http.post('outgoingdocument/exchange_documents', payload);
  }
  updateMultiStatus(payload): Observable<any> {
    return this.http.post('outgoingdocument/update_multi_status', payload);
  }
  approveMultiDocuments(payload): Observable<any> {
    return this.http.post('document/approve_multi_document', payload);
  }
  getCanceledOutgoingDocsForCoordinator(filters): Observable<any> {
    return this.http.get('outgoingdocument/for_coordinator', {
      params: filters,
    });
  }
  getOutgoingDocsForCreator(filters): Observable<any> {
    return this.http.get('outgoingdocument/documents_for_creator', {
      params: filters,
    });
  }
  getCanceledDocsForCoordinator(filters): Observable<any> {
    return this.http.get('document/for_coordinator', {
      params: filters,
    });
  }
  getUnapprovedDocsForCoordinator(filters): Observable<any> {
    return this.http.get('document/unapproved_for_coordinator', {
      params: filters,
    });
  }
  getCurrentUserInfo(): Observable<any> {
    return this.http.get('document/currentuser-info');
  }
  sendTemplate(payload): Observable<any> {
    return this.http.post('document/create-with-template', payload);
  }
  cancelTemplate(payload): Observable<any> {
    return this.http.put(`document/cancel_unapproved_document`, payload);
  }
  deleteDocument(entityId): Observable<any> {
    return this.http.delete(`document/${entityId}`);
  }
  getTemplateCommandText(params): Observable<any> {
    return this.http.get('document/template-command-text', {params});
  }
  setTemplateCommandText(payload): Observable<any> {
    return this.http.put('document/template-command', payload);
  }
  getUnapprovedDetailsForCoordinator(params): Observable<any> {
    return this.http.get('document/unapproved_details_for_coordinator', {params});
  }
  editTemplate(payload): Observable<any> {
    return this.http.put(`document/update_before_approve_document`, payload);
  }
  approveDocumentFromCoordinator(payload): Observable<any> {
    return this.http.post(`document/approve_document`, payload);
  }
  deleteDocumentFileForCoordinator(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body,
    };
      return this.http.delete('document/file', options);
  }
  sendOutsideTemplate(payload): Observable<any> {
    return this.http.post('document/create-with-template-outside-app', payload);
  }

  createLetter(payload: any): Observable<any> {
    const formData = new FormData();
    formData.append('Title', payload.Title);
    formData.append('Description', payload.Description);
    formData.append('FirstName', payload.FirstName);
    formData.append('LastName', payload.LastName);
    formData.append('Institution', payload.Institution);
    formData.append('PapersCount', payload.PapersCount);
    formData.append('Type', payload.Type);
    formData.append('Note', payload.ChancelleryPersonNote);
    if (payload.Files?.length > 0) {
      payload.Files.map((file) => {
        formData.append('Files', file);
      });
    }
    return this.http.post('document/create-with-template', formData);
  }
  updateLetter(payload: any): Observable<any> {
    const formData = new FormData();
    formData.append('EntityId', payload.EntityId);
    formData.append('Title', payload.Title);
    formData.append('Description', payload.Description);
    formData.append('FirstName', payload.FirstName);
    formData.append('LastName', payload.LastName);
    formData.append('Institution', payload.Institution);
    formData.append('PapersCount', payload.PapersCount);
    // formData.append('Type', payload.Type);
    formData.append('Note', payload.ChancelleryPersonNote);
    if (payload.Files?.length > 0) {
      payload.Files.map((file) => {
        formData.append('Files', file);
      });
    }
    return this.http.put('document', formData);
  }
}
