<a routerLink="/">
	<!-- <img alt="Logo" [attr.src]="headerLogo" /> -->
</a>

<div class="d-flex align-items-center box">
	<ng-container *ngIf="asideSelfDisplay">
		<button class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
			<span></span>
		</button>
	</ng-container>

	<app-change-language class="text-center"></app-change-language>

	<button (click)="showMobileToggler = !showMobileToggler" class="btn btn-hover-text-primary p-0 ml-2" id="kt_header_mobile_topbar_toggle">
		<span [inlineSVG]="'./assets/svg/User.svg'" cacheSVG="true" class="svg-icon svg-icon-xl"></span>
  </button>
  <div class="dropdown" tabindex="-1" (blur)="showMobileToggler = false" *ngIf="showMobileToggler && roles.indexOf('Admin') > -1">
    <ul>
      <li (click)="toggleMenu.emit()">{{'password_change' | translate}}</li>
    </ul>
  </div>
</div>
