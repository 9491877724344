import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PreventZoomGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): boolean {
    const currentUser = JSON.parse(localStorage.getItem('userRoles'))[0];

    if (JSON.parse(localStorage.getItem('userRoles')).indexOf('Zoom-ის ადმინისტრატორი') > -1) {

        this.router.navigate(['/']);
        return false;

    }

    return true;
    }
}
