import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {dateToString, iconPath} from '../../../utils';
import {ScheduleService} from '../../shared/schedule.service';
import {SnackBarComponent} from '../../shared/components';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ScheduleTableComponent} from '../schedule-table/schedule-table.component';

@Component({
  selector: 'app-schedule-add',
  templateUrl: './schedule-add.component.html',
  styleUrls: ['./schedule-add.component.scss']
})
export class ScheduleAddComponent implements OnInit {
  icon: string = iconPath;
  addForm: FormGroup = new FormGroup({
    entityId: new FormControl(null),
    group: new FormControl(''),
    base: new FormControl(''),
    address: new FormControl(''),
    date: new FormControl(''),
    hour: new FormControl(''),
    type: new FormControl(''),
    curation: new FormControl(''),
    lecturer: new FormControl(''),
    audience: new FormControl(''),
    hourCount: new FormControl(1),
    zoomLink: new FormControl(''),
    subGroupNumber: new FormControl(null)
  });
  groups: any = [];
  types: any = [
    {value: 'PBL', display: 'PBL'},
    {value: 'Lecture', display: 'Lecture'},
    {value: 'Seminar', display: 'Seminar'},
    {value: 'CADM', display: 'CADM'},
    {value: 'ExternalSubject', display: 'ExternalSubject'},
    {value: 'ClinicalSeminar', display: 'ClinicalSeminar'},
    {value: 'ClinicalLecture', display: 'ClinicalLecture'},
  ];
  curations: any = [];
  lecturers: any = [];
  audiences: any = [];
  hours: any = [
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
  ];
loader: boolean = false;
  constructor(
    private service: ScheduleService,
    private snackbar: MatSnackBar,
    private dialog: MatDialogRef<ScheduleTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { schedule: any, single: boolean, info: any }
  ) { }

  ngOnInit(): void {
    if (this.data.schedule) {
      this.addForm.patchValue(this.data.schedule);
      // console.log(this.addForm.value);
    }
    this.getGroups();
    this.getLecturers();
    this.getAudiences();
  }

  changeInput(type, event): void {
    this.addForm.get(type).patchValue(event.name);
  }

  sendData(): void {
    this.loader = true;
    const sendData = {
      ...this.addForm.value,
      date: typeof(this.addForm.value.date) === 'string' ? this.addForm.value.date : dateToString(this.addForm.value.date),
      subGroupNumber: typeof(this.addForm.value.subGroupNumber) === 'string' ? Number(this.addForm.value.subGroupNumber) : this.addForm.value.subGroupNumber
    };
    if (!this.data.schedule) {
      delete sendData.entityId;
      this.service.postData(sendData, this.addForm.value.hourCount).subscribe(res => {
        this.loader = false;
        this.openSnackBar('success', 'success');
        this.dialog.close();
      }, error => {
        this.loader = false;
        this.openSnackBar(error.error.errors[0].message, 'error', true);
      });
      return;
    }
    if (this.data.single) {
      this.service.updateData({schedule: sendData, entityIds: this.data.schedule.ids, hourCount: sendData.hourCount}).subscribe(res => {
        this.loader = false;
        this.openSnackBar('success', 'success');
        this.dialog.close();
      }, error => {
        this.loader = false;
        this.openSnackBar(error.error.errors[0].message, 'error', true);
      });
      return;
    }
    this.service.updateSingleData(sendData).subscribe(res => {
      this.loader = false;
      this.openSnackBar('success', 'success');
      this.dialog.close();
    }, error => {
      this.loader = false;
      this.openSnackBar(error.error.errors[0].message, 'error', true);
    });
  }

  getLecturers(): void {
    this.service.getLecturers().subscribe(res => {
      this.lecturers = res.records.map(item => {
        return {
          value: item.shortNameEng,
          name: item.shortNameEng
        };
      });
    });
  }

  getGroups(): void {
    this.service.getGroups().subscribe(res => {
      this.groups = res.records;
    });
  }

  getAudiences(): void {
    this.service.getAudiences().subscribe(res => {
      this.audiences = res.records.map(item => {
        return {
          name: item.number
        };
      });
    });
  }

  private openSnackBar(message: string, type: string, notTranslate = false): void {
    this.snackbar.openFromComponent(SnackBarComponent, {
      data: {
        type,
        message,
        notTranslate
      },
      panelClass: type,
      duration: 3000,
    });
  }
}
