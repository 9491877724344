<div class="modal-header">
<h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{message}}
</div>
<div class="modal-footer">
  <button (click)="dismiss()" [translate]="newBtnCancel"  mat-raised-button class="btn btn-outline-secondary" [style]="customStyle">
    {{ newBtnCancel }}
  </button>
  <button (click)="decline()" [translate]="btnCancelText"  mat-raised-button class="btn btn-outline-secondary" style="width: 150px;">
    {{ btnCancelText }}
  </button>
  <button (click)="accept()" mat-raised-button class="btn btn-primary" style="width: 150px;">
    {{ btnOkText }}
  </button>
</div>
