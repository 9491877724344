import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {dateToString, stringToDate} from '../../../utils';
import {MatDialogRef} from "@angular/material/dialog";
import {LecturersComponent} from "../../features/lecturers/pages/lecturers/lecturers.component";

@Component({
  selector: 'app-presure-configuration',
  templateUrl: './presure-configuration.component.html',
  styleUrls: ['./presure-configuration.component.scss']
})
export class PresureConfigurationComponent implements OnInit {
  
  presureForm: FormGroup = new FormGroup({
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required)
  });
  loader = false;
  constructor(private httpClient: HttpClient, private dialogRef: MatDialogRef<LecturersComponent>) { }

  ngOnInit(): void {
    this.httpClient.get(`lecturers/hours_dates`).subscribe((res: any) => {
      this.presureForm.patchValue({
        fromDate: stringToDate(res.fromDate.split('T')[0]),
        toDate: stringToDate(res.toDate.split('T')[0]),
      });
    });
  }

  exportData(): void {
    this.loader = true;
    this.httpClient.post(`lecturers/hours_dates`, {
      fromDate: dateToString(this.presureForm.value.fromDate),
      toDate: dateToString(this.presureForm.value.toDate)
    }).subscribe((res: any) => {
      this.loader = false;
      this.dialogRef.close();
    }, (error)=> {
      this.loader = false;
    });
  }

}
