import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SheetsService {
  biostatisticObservable = new Subject<any>();

  constructor(private http: HttpClient) {}

  // getCurations
  getCurations(status): Observable<any> {
    return this.http.get('lecturercard/curations', { params: status });
  }

  forAdmin(status): Observable<any> {
    return this.http.get('lecturercard/curations-for-administrator', {
      params: status,
    });
  }

  uploadFile(formData): Observable<any> {
    return this.http.post('scheduleimport', formData);
  }

  archiveGroup(body): Observable<any> {
    return this.http.post('schedulegroup/archive', body);
  }

  // Recoveries
  getRecoveries(params): Observable<any> {
    return this.http.get('recoverygroup/search', { params });
  }

  getStudentsForRecovery(
    curationId: string,
    selectedType: any
  ): Observable<any> {
    return this.http.get('recoverygroup/get-students', {
      params: { curationId, type: selectedType },
    });
  }

  deleteRecovery(entityId: string): Observable<any> {
    return this.http.delete(`recoverygroup/${entityId}`);
  }

  getRecoveryGeneralInfo(groupId): Observable<any> {
    return this.http.get(`recoverygroup/${groupId}/details`);
  }

  getGroupedData(query): Observable<any> {
    return this.http.get(`totalscores/search`, { params: query });
  }
  getGroupedDataArchived(query): Observable<any> {
    return this.http.get(`totalscores/search-archived`, { params: query });
  }

  getGroupedDataTable(query): Observable<any> {
    return this.http.get(`totalscores`, { params: query });
  }

  getRecoveryAbsScores(query): Observable<any> {
    return this.http.get(`recoverygroup/get-score`, { params: query });
  }

  setRecoveryScore(body): Observable<any> {
    return this.http.post(`recoverygroup/add-score`, body);
  }

  // getInfo
  public getModuleGeneralInfo(groupId): Observable<any> {
    return this.http.get(`groups/${groupId}/details`);
  }

  // modules

  getModuleList(): Observable<any> {
    return this.http.get('modules/for-list', {
      params: {
        page: '0',
        pageSize: '1000',
      },
    });
  }

  getPrequizTable(params): Observable<any> {
    return this.http.get('totalscores/prequiz', { params });
  }
  getPrequizTableArchived(params): Observable<any> {
    return this.http.get('totalscores/prequiz-archived', { params });
  }

  getPrequizDetails(params, quiz: string): Observable<any> {
    if (quiz === 'true') {
      return this.http.get('totalscores/quiz_details', { params });
    }
    return this.http.get('totalscores/prequiz_details', { params });
  }

  // LecturerScore

  // getStudents
  getStudents(queryParams: any): Observable<any> {
    return this.http.get('lecturescore/get-students', { params: queryParams });
  }

  // setDay
  setDay(body: any): Observable<any> {
    return this.http.post('lecturescore/add-day', body);
  }

  setSeminarDayToScore(body: any): Observable<any> {
    return this.http.post('seminarscore/add-day', body);
  }

  setCurationRecoveryDay(body: any): Observable<any> {
    return this.http.post('recoverygroup/add-day', body);
  }

  setExternalDay(body: any): Observable<any> {
    return this.http.post('externalsubjectscore/add-day', body);
  }

  setExternalScore(body: any): Observable<any> {
    return this.http.post('externalsubjectscore/add–score', body);
  }

  // setScore
  setScore(body: any): Observable<any> {
    return this.http.post('lecturescore/add-score', body);
  }

  setScoreRecovery(body: any): Observable<any> {
    return this.http.post('recoveryscore/add-score', body);
  }

  setScoreSeminar(body: any): Observable<any> {
    return this.http.post('seminarscore/add-score', body);
  }

  // Additional Data
  getCurationsList(query): Observable<any> {
    return this.http.get('curations/for-list', { params: query });
  }

  getLecturersList(): Observable<any> {
    return this.http.get('lecturers/list');
  }

  // Recoveries

  createRecovery(body): Observable<any> {
    return this.http.post('recoverygroup', body);
  }

  updateRecovery(body): Observable<any> {
    return this.http.put('recoverygroup', body);
  }

  // Seminars

  // getStudents
  getSeminarStudents(queryParams: any): Observable<any> {
    return this.http.get('seminarscore/get-students', { params: queryParams });
  }

  getExternalsScore(queryParams: any): Observable<any> {
    return this.http.get('externalsubjectscore/get-students', {
      params: queryParams,
    });
  }

  // setDay
  setSeminarDay(body: any): Observable<any> {
    return this.http.post('seminarscore/add-day', body);
  }

  // setScore
  setSeminarScore(body: any): Observable<any> {
    return this.http.post('seminarscore/add-score', body);
  }

  // getTotals
  getSeminarTotals(queryParams: any): Observable<any> {
    return this.http.get('totalscores', { params: queryParams });
  }

  getExternalTotals(queryParams: any): Observable<any> {
    return this.http.get('externalsubjectscore/get-total-scores', {
      params: queryParams,
    });
  }

  getRecoveryTotals(queryParams: any): Observable<any> {
    return this.http.get('recoverygroup/get-total-scores', {
      params: queryParams,
    });
  }

  setRecoverySummaryScore(type, body: any): Observable<any> {
    return this.http.post(`recoveryscore/${type}`, body);
  }

  // OralScores
  setExamScore(body: any): Observable<any> {
    return this.http.post('seminarscore/add-oral-exam-score', body);
  }

  // Try 1
  setExamScoreSec(body: any): Observable<any> {
    return this.http.post('seminarscore/add-oral-exam-score-recovery1', body);
  }

  // try 2
  setExamScoreThird(body: any): Observable<any> {
    return this.http.post('seminarscore/add-oral-exam-score-recovery2', body);
  }

  // Quiz
  setQuiz(body: any): Observable<any> {
    return this.http.post('seminarscore/add-quiz-score', body);
  }

  // try 1
  setQuizSec(body: any): Observable<any> {
    return this.http.post('seminarscore/add-quiz-score-recovery1', body);
  }

  // try 2
  setQuizThird(body: any): Observable<any> {
    return this.http.post('seminarscore/add-quiz-score-recovery2', body);
  }

  getLecturerScores(queryParams: any): Observable<any> {
    return this.http.get('lecturersevaluation/lecturers-evaluation', {
      params: queryParams,
    });
  }

  // getPblData

  // Students
  getPblStudents(queryParams: any): Observable<any> {
    return this.http.get('pblscore/get-students', { params: queryParams });
  }
  getLaboratoryStudents(queryParams: any): Observable<any> {
    return this.http.get('laboratoryscore/get-students', { params: queryParams });
  }
  // Cases
  getPblCasess(queryParams: any): Observable<any> {
    return this.http.get('pblscore/get-pblcase-list', { params: queryParams });
  }

  // Add Cases
  addPblCase(body: any): Observable<any> {
    return this.http.post('pblscore/add-pblcase', body);
  }

  updatePblCase(body: any): Observable<any> {
    return this.http.put('pblscore/update-pblcase', body);
  }

  // Add Pbl Score
  addPblPerperation(body: any): Observable<any> {
    return this.http.post('pblscore/add-preparation-score', body);
  }

  // Add Pbl Score
  addPblContributuon(body: any): Observable<any> {
    return this.http.post('pblscore/add-contribution-score', body);
  }

  // Add Pbl Score
  addPblAtitude(body: any): Observable<any> {
    return this.http.post('pblscore/add-atitude-score', body);
  }

  // Add Pbl Score
  addPblDay(body: any): Observable<any> {
    return this.http.post('pblscore/add-day', body);
  }
  addLaboratoryDay(body: any): Observable<any> {
    return this.http.post('laboratoryscore/add-day', body);
  }

  addPblDescScore(body: any, type: string): Observable<any> {
    return this.http.post(`pblscore/add-${type}`, body);
  }
  addLabDescScore(body: any): Observable<any> {
    return this.http.post(`laboratoryscore/add-score`, body);
  }
  addLabDescLab(body: any): Observable<any> {
    return this.http.post(`laboratoryscore/add-lab`, body);
  }

  // Archives
  archiveCuration(body: any): Observable<any> {
    return this.http.put(`groups/archive-curation`, body);
  }
  archiveExternalCuration(body: any): Observable<any> {
    return this.http.put(`externalsubjects/archive`, body);
  }

  unArchiveCuration(body: any): Observable<any> {
    return this.http.put(`groups/unarchive-curation`, body);
  }
  unArchiveExternalCuration(body: any): Observable<any> {
    return this.http.put(`externalsubjects/unarchive`, body);
  }

  // Delete PBL Case
  deletePblCase(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body,
    };
    return this.http.delete('pblscore/delete-pblcase', options);
  }

  // Recovery
  archiveRecovery(body): Observable<any> {
    return this.http.put(`recoverygroup/archive`, body);
  }

  unArchiveRecovery(body): Observable<any> {
    return this.http.put(`recoverygroup/unarchive`, body);
  }

  // lecturerCard for Admin
  getLecturerCard(queryParams): Observable<any> {
    return this.http.get('lecturercard/curations-for-administrator', {
      params: queryParams,
    });
  }

  getGroups(params): Observable<any> {
    return this.http.get(`schedulegroup`, { params });
  }

  deleteGroup(body): Observable<any> {
    return this.http.delete(`schedulegroup`, { params: body });
  }

  uploadQuizOrSystem(body, params, type): Observable<any> {
    if(type === 3) {
      return this.http.post(`quizimport/system`, body, { params });
    } else {
      return this.http.post(`quizimport`, body, { params });
    }
  }
  uploadFilterFile(body, params, filterOrSystem): Observable<any> {
    if(filterOrSystem === 'system') {
      return this.http.post(`quizimport/filter-system`, body, { params });
    } else {
      return this.http.post(`filterimport`, body, { params });
    }
  }

  // AllowedLectures
  setLecturer(body): Observable<any> {
    return this.http.post(`seminarscore/add-allowed-lecturer`, body);
  }
  setExternalLecturer(body): Observable<any> {
    return this.http.post(`externalsubjects/add-allowed-lecturer`, body);
  }

  removeLecturer(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body,
    };
    return this.http.delete(`seminarscore/delete-allowed-lecturer`, options);
  }
  removeExternalLecturer(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body,
    };
    return this.http.delete(`externalsubjects/delete-allowed-lecturer`, options);
  }

  // * * *   EVALUATIONS  * * *
  addInterviewScore(payload, isExternal?: boolean): Observable<any> {
    if(isExternal) {
      return this.http.post('externalsubjectscore/add-interview', payload);
    } else {
      return this.http.post('totalscores/add-interview', payload);
    }
  }

  addNotes(payload, isExternal?: boolean): Observable<any> {
    if(isExternal) {
      return this.http.post('externalsubjectscore/add-notes', payload);
    } else { 
      return this.http.post('totalscores/add-notes', payload);
    }
  }

  addSimulatedPatient(payload, isExternal?: boolean): Observable<any> {
    if(isExternal) {
      return this.http.post('externalsubjectscore/add-simulated-patient', payload);
    } else {
      return this.http.post('totalscores/add-simulated-patient', payload);
    }
  }

  addInterViewRecovery(payload, isExternal?: boolean): Observable<any> {
    if(isExternal) {
      return this.http.post('externalsubjectscore/add-interview-recovery', payload);
    } else {
      return this.http.post('totalscores/add-interview-recovery', payload);
    }
  }

  addNotesRecovery(payload, isExternal?: boolean): Observable<any> {
    if(isExternal) {
      return this.http.post('externalsubjectscore/add-notes-recovery', payload);
    } else {
      return this.http.post('totalscores/add-notes-recovery', payload);
    }
  }

  addSimulatedPatientRecovery(payload, isExternal?: boolean): Observable<any> {
    if(isExternal) {
      return this.http.post('externalsubjectscore/add-simulated-patient-recovery', payload);
    } else {
      return this.http.post('totalscores/add-simulated-patient-recovery', payload);
    }
  }

  getLastWeek(params): Observable<any> {
    return this.http.get('lecturescore/last-week', { params });
  }

  getBiostatistic(params): Observable<any> {
    return this.http.get('biostatisticscore/get-students', { params });
  }

  addBiostatisticDay(payload): Observable<any> {
    return this.http.post('biostatisticscore/add-day', payload);
  }
  addBiostatisticScore(payload): Observable<any> {
    return this.http.post('biostatisticscore/add-score', payload);
  }
  deleteBiostatisticDay(payload): Observable<any> {
    return this.http.post('biostatisticscore/delete-day', payload);
  }

  getFilterSubjects(params): Observable<any> {
    return this.http.get('filter/subjects', { params });
  }
  postFilterSubjects(payload): Observable<any> {
    return this.http.post('filter', payload);
  }
  editFilterDetails(payload): Observable<any> {
    return this.http.put('filter/edit', payload);
  }
  getFilterData(params): Observable<any> {
    return this.http.get('filter/search', { params });
  }
  getPrefilterData(params): Observable<any> {
    return this.http.get('filter/search', { params });
  }
  getFilterDetails(params): Observable<any> {
    return this.http.get('filter/details', { params });
  }
  getPrefilterDetails(params): Observable<any> {
    return this.http.get('filter/prefilter-details', { params });
  }
  deleteFilter(entityId: string): Observable<any> {
    return this.http.delete(`filter/${entityId}`);
  }
  postExtraScore(payload): Observable<any> {
    return this.http.post('filter/extra-point', payload);
  }
  uploadFilterScores(body, params): Observable<any> {
    return this.http.post(`filterimport`, body, { params });
  }
  getPBLData(params: any, activeUser: string): Observable<any> {
    if (activeUser === 'Lecturer') {
      return this.http.get('grouppblsemester/groups-for-lecturer', { params });
    } else {
      return this.http.get('grouppblsemester/groups', { params });
    }
  }
  getPBLCurationDetails(params: any, activeUser: string): Observable<any> {
    if (activeUser === 'Lecturer') {
      return this.http.get('grouppblsemester/group-details-for-lecturer', { params });
    } else {
      return this.http.get('grouppblsemester/group-details', { params });
    }
  }
  pblCurationDetailsStudents(params: any): Observable<any> {
    return this.http.get('grouppblsemester/get-students', { params });
  }
  pblLastWeek(params: any): Observable<any> {
    return this.http.get('grouppblsemester/last-week', { params });
  }
  pblAddDay(payload): Observable<any> {
    return this.http.post('grouppblsemester/add-day', payload);
  }
  pblAddScore(payload): Observable<any> {
    return this.http.post('grouppblsemester/add-score', payload);
  }
  pblCaseData(params: any): Observable<any> {
    return this.http.get('pblsemesterpblcase/cases', { params });
  }
  pblCaseSubgroups(params: any): Observable<any> {
    return this.http.get('pblsemesterpblcase/subgroups', { params });
  }
  pblCaseAddDay(payload: any): Observable<any> {
    return this.http.post('pblsemesterpblcase/add-day', payload);
  }
  pblCaseAddScore(payload: any): Observable<any> {
    return this.http.post('pblsemesterpblcase/add-score', payload);
  }
  pblCaseAddPreparationScore(payload: any): Observable<any> {
    return this.http.post('pblsemesterpblcase/add-preparation-score', payload);
  }
  pblCaseAddContributionScore(payload: any): Observable<any> {
    return this.http.post('pblsemesterpblcase/add-contribution-score', payload);
  }
  pblCaseAddGceScore(payload: any): Observable<any> {
    return this.http.post('pblsemesterpblcase/add-gce-score', payload);
  }
  pblCaseAddAttitudeScore(payload: any): Observable<any> {
    return this.http.post('pblsemesterpblcase/add-attitude-score', payload);
  }

  postExcusableAbs(payload: any): Observable<any> {
    return this.http.post('totalscores/excusable-abs', payload);
  }
  addClinicalSkills(payload: any): Observable<any> {
    return this.http.post('totalscores/add-clinicalskills', payload);
  }

  getExternalSubjectStudents(params: any): Observable<any> {
    return this.http.get('externalsubjects/get-students', { params });
  }
  externalSubjectAddDay(payload): Observable<any> {
    return this.http.post('externalsubjects/add-day', payload);
  }
  externalSubjectAddScore(payload): Observable<any> {
    return this.http.post('externalsubjects/add-score', payload);
  }
  externalSubjectLastWeek(params: any): Observable<any> {
    return this.http.get('externalsubjects/last-week', { params });
  }

  getGroupedDataTableForExternal(query): Observable<any> {
    return this.http.get(`externalsubjectscore/get-total-scores`, { params: query });
  }
  addExternalTotalScore(payload): Observable<any> {
    return this.http.post('externalsubjects/add-total-score', payload);
  }
  postExternalExcusableAbs(payload: any): Observable<any> {
    return this.http.post('externalsubjects/excusable-abs', payload);
  }
  postExternalSubjectLecturer(payload: any): Observable<any> {
    return this.http.post('externalsubjects/add-lecturer', payload);
  }
  getSystesDetails(params): Observable<any> {
    return this.http.get('filter/systems', { params });
  }
  getFilterSystemDetails(params): Observable<any> {
    return this.http.get('filter/system-details', { params });
  }
  getCbtDetails(params): Observable<any> {
    return this.http.get('externalsubjectscore/cbt-details', { params });
  }
}
