<div class="auth-container d-lg-none">
  <div class="auth-header-wrapper d-flex flex-column justify-content-center">
    <app-change-language class="align-self-end m-4"></app-change-language>
    <div class="logo-text"></div>
  </div>
  <div class="auth-bottom-wrapper position-relative">
    <div class="auth-bg"></div>
    <div class="position-relative">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="d-none d-lg-block university-bg">
  <div class="university-bg-overlay d-flex">
      <app-change-language></app-change-language>
    <div
      class="auth-left-wrapper d-flex flex-column justify-content-center align-items-center"
    >
      <div class="logo-text"></div>
    </div>
    <div class="auth-right-wrapper">
      <div class="routing-container d-flex justify-content-center flex-column">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
