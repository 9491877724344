<div class="modal-header">
  <h1 mat-dialog-title class="modal-title">
    {{"add_lecturer" | translate}}
  </h1>
  <app-dialog-close-icon mat-dialog-close></app-dialog-close-icon>
</div>
<div class="body">
    <app-dropdown [placeholder]="'lecturer' | translate" [list]="lecturers" [inputField]="true" (valueChange)="searchValue($event)"></app-dropdown>
    <div class="table" *ngIf="allowedLecturers.length">
      <div class="header">
        {{'user_full_name' | translate}}
      </div>
      <div class="table-body">
        <div class="row" *ngFor="let item of allowedLecturers; let i = index">
          <div class="col long">{{item.name}}</div>
          <div class="col short">
            <app-trash-icon
              (click)="remove(item, i)"
              class="mx-3 cursor-pointer"
            ></app-trash-icon>
          </div>
        </div>
      </div>
    </div>
</div>
