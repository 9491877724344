import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import {TranslateService} from "@ngx-translate/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SnackBarComponent implements OnInit {
  message = '';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBar: MatSnackBar, private translateService: TranslateService) { }

  ngOnInit(): void {
    if (this.data.notTranslate) {
      this.message = this.data.message;
    } else {
      this.message = this.translateService.instant('Dialog.' + this.data.message);
    }
  }

  dismissSnackbar(): void {
    if (this.data.notTranslate) {
      this.message = this.data.message;
    } else {
      this.message = this.translateService.instant('Dialog.' + this.data.message);
    }
    this.snackBar.dismiss();
  }
}
