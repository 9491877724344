import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { iconPath } from '../../../../utils';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  icon: string = iconPath;
  isVisible: boolean = true;
  timer: any;
  @Input() text: string;
  ngOnInit(): void {
    this.isVisible = true;
    this.timer = setTimeout(() => {
      this.isVisible = false;
    }, 3500);
  }
  ngOnDestroy() {
    clearTimeout(this.timer);
  }
}
