import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './containers/auth-layout/auth-layout.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { CoreModule } from './core/core.module';
import { MetronicModule } from './shared/modules';
import { SharedModule } from './shared/shared.module';
import { FeedbackBoxComponent } from './containers/layout/feedback-box/feedback-box.component';
import { ScheduleAddComponent } from './features/schedule-add/schedule-add.component';
import { LectSwitcherModule } from './features/library/components/lect-switcher/lect-switcher.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';

@NgModule({
  declarations: [AppComponent, LayoutComponent, AuthLayoutComponent, FeedbackBoxComponent, ScheduleAddComponent],
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    SharedModule,
    InlineSVGModule.forRoot(),
    MetronicModule,
    AppRoutingModule,
    LectSwitcherModule,
    NgbModule
  ],
  providers: [BnNgIdleService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
