import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-year-dropdown',
  templateUrl: './year-dropdown.component.html',
  styleUrls: ['./year-dropdown.component.scss']
})
export class YearDropdownComponent implements OnInit {
  @Input() yearSize = 12;
  @Input() controlName;
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() reversed = false;
  yearList = [];
  activeYear: any;
  @Input() initialYear: number = 2020;

  constructor() { }

  ngOnInit(): void {
    this.yearList = [];
    // const year = new Date().getFullYear();
    const year = this.initialYear;
    if (this.reversed) {
      for (let i = this.yearSize; i > 0; i--) {
        this.yearList.push({name: `${year - i - 1 + 2}/${year - i + 2}`, id: i});
      }
    } else {
      for (let i = 0; i < this.yearSize; i++) {
        this.yearList.push({name: `${year + i}/${year + i + 1}`, id: i});
      }
    }
    if (this.controlName.length) {
      this.activeYear = {name: this.controlName};
    } else {
      this.activeYear = this.reversed ? this.yearList[this.yearSize - 1] : this.yearList[0];
    }
  }

  setValue(event): void {
    this.changeValue.emit(event.source.value);
  }

  getChange(): void {
    this.yearList = [];
    // const year = new Date().getFullYear();
    const year = this.initialYear;
    if (this.reversed) {
      for (let i = this.yearSize; i > 0; i--) {
        this.yearList.push({name: `${year - i - 1 + 2}/${year - i + 2}`, id: i});
      }
    } else {
      for (let i = 0; i < this.yearSize; i++) {
        this.yearList.push({name: `${year + i}/${year + i + 1}`, id: i});
      }
    }
  }
}
