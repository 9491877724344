<div class="modal-header">
  <h1 mat-dialog-title class="modal-title">
    {{ "ხარვეზის დაფიქსირება" | translate }}
  </h1>
  <app-dialog-close-icon mat-dialog-close></app-dialog-close-icon>
</div>

<div style="padding: 30px">
  <form [formGroup]="feedBackForm">
    <div>
      <textarea
        placeholder="ტექსტი"
        formControlName="text">
      ></textarea>
    </div>
  </form>
  <button mat-raised-button color="primary" class="mr-5" (click)="saveData()">
    გაგზავნა
  </button>
</div>
