import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageHelperService {

    get currentLanguageCode(): string {
        let result = '';

        switch (this.translateService.currentLang) {
            case('ka-GE'):
                result = 'Geo';
                break;
            case('en-US'):
                result = 'Eng';
                break;
        }
        return result;
    }

    constructor(private translateService: TranslateService) {}
}
