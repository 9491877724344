import { SharedModule } from './../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LectSwitcherComponent } from './lect-switcher.component';



@NgModule({
  declarations: [LectSwitcherComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class LectSwitcherModule { }
